import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    toast: {
        severity: null,
        message: null,
        show: false
    }
}

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        showToast: (state, { payload }) => payload,
        resetToast: () => initialState
    },
})

// Action creators are generated for each case reducer function
export const { showToast, resetToast } = toastSlice.actions;

export default toastSlice.reducer;