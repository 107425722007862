import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { withRouter, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
//import LockIcon from '@mui/icons-material/Lock';

import { setAuthToken } from '../../utils';
import LoginImage from '../images/buddy-dual-wielder.png';
import { toggleLoading } from '../../slices/loading';

const LoginPage = (props) => {
  const [messageText, setMessageTxt] = useState(null);
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    heroImage: {
      maxHeight: 192,
      margin: '2rem auto',
      display: 'block'
    }
  }));

  const classes = useStyles();

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = form => handleLogin(form);

  const handleLogin = (form) => {
    dispatch(toggleLoading(true));
    setMessageTxt(null);
    const loginPayload = {
      email_or_username: form.email,
      password: form.password
    }
    axios.post("https://gasapi.info/api/v1/auth/login", loginPayload)
      .then(response => {
        const token = response.data.data.access;
        const refresh = response.data.data.refresh;
        localStorage.setItem('token', token);
        localStorage.setItem('refresh', refresh);
        setAuthToken(token);
        props.history.push(props.location.state ? props.location.state.referrer.pathname : '/buddy/');
        dispatch(toggleLoading(false));
      })
      .catch(err => {
        dispatch(toggleLoading(false));
        err.response ? setMessageTxt(err.response.data.message) : console.log(err);
      });
  };

  return (
    <Container maxWidth="sm">
      <img src={LoginImage} alt="GAC Buddy" className={classes.heroImage} />
      <Typography component="h1" variant="h4" align="center">Login</Typography>
      {/*<Box pt={2}>
        <Alert
          action={
            <Button color="inherit" size="small" component={NavLink} to="/register/">
              Register Now
            </Button>
          }
          icon={<LockIcon fontSize="inherit" />}
          variant="outlined" severity="warning"
        >
          Not yet registered?
        </Alert>
        </Box>*/}
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          id="email" label="Username or Email" variant="filled" fullWidth
          margin="normal" {...register("email", { required: true })}
          error={errors.email ? true : false}
        />
        <TextField
          id="password" label="Password" variant="filled" fullWidth margin="normal"
          type="password" {...register("password", { required: true })}
          error={errors.password ? true : false}
        />
        {messageText &&
          <Alert variant="filled" severity="error">{messageText}</Alert>
        }
        <Box pt={2} pb={4}>
          <Stack
            direction={{ xs: 'row' }} spacing={2}
            className="gas-button-stack"
          >
            <Button component={NavLink} to="/forgot-password/" fullWidth variant="text">Forgot Password</Button>
            <Button type="submit" color="primary" variant="contained" fullWidth>Login</Button>
          </Stack>
        </Box>
      </Box>
      
    </Container>
  );
}
export default withRouter(LoginPage);