import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

import CompareProfile from './profile';
import './style.scss';
import CompareSummary from './summary';

const PlayCompare = (props) => {
  const userStats = useSelector((state) => state.stats);
  const playerData = userStats[userStats.length - 1];
  const { opponent } = props;

  return (
    <Box className="gas-play-compare">
      {playerData &&
        <Grid container spacing={2}>
          <Grid item xs>
            <CompareProfile name={playerData.user.game_name} code={playerData.ally_code_index} />
          </Grid>
          <Grid item xs="auto">
            <Divider orientation="vertical">
              <Chip label="VS" color="secondary" />
            </Divider>
          </Grid>
          <Grid item xs>
            {opponent && <CompareProfile name={opponent.opponent_in_game_name} code={opponent.opponent_ally_code} />}
          </Grid>
        </Grid>
      }
      <CompareSummary userStats={playerData} />
    </Box>
  );
}

export default PlayCompare;