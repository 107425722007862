import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

import PlayCountdown from '../../components/play/countdown';
import PlanMap from '../../components/plan/map';
import SelectOpponent from '../../components/play/selectOpponent';

const useStyles = makeStyles((theme) => ({
  altBg: {
    background: theme.palette.grey['900']
  },
  heroMeta: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const PlayAttack = (props) => {
  const opponent = useSelector((state) => state.opponent);
  const classes = useStyles();
  return (
    <Box>

      <Container maxWidth="md" className={classes.altBg}>
        <Box py={2}>
          <Typography align="center" component="h1" variant="h4">Attack</Typography>
          <Box pt={2} className={classes.heroMeta}>
            {opponent && <Typography align="left" component="p" variant="body1">vs {opponent.opponent_in_game_name}</Typography>}
            <PlayCountdown date="2023-03-21T22:00:00" title="Ends in" status="defense" meta />
          </Box>
        </Box>
      </Container>

      {opponent ?
        <PlanMap attack />
        :
        <Container maxWidth="sm">
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item xs={12}>
              <SelectOpponent mb={2} pt={2} />
            </Grid>
          </Grid>
        </Container>
      }

    </Box>
  );
}

export default PlayAttack;