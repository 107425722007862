import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { toggleLoading } from '../../slices/loading';
import { showToast } from '../../slices/toast';

const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center'
    },
  }
}));

const SnapshotDetails = (props) => {
  const [messageText, setMessageTxt] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = form => handleCreate(form);

  const handleCreate = (form) => {
    dispatch(toggleLoading(true));
    setMessageTxt(null);

    const bodyPayload = {
      name: form.description
    }
    axios.post("https://gasapi.info/api/v1/roster-snapshot", bodyPayload)
      .then(response => {
        dispatch(toggleLoading(false));
        dispatch(showToast({ show: true, message: 'Snapshot created succesfully.', severity: 'success' }));
        props.reloadList();
        props.click(false);
      })
      .catch(err => {
        dispatch(toggleLoading(false));
        err.response ? setMessageTxt(err.response.data.message) : console.log(err);
      });
  };

  return (

    <Dialog open={props.open} onClose={() => props.click(false)} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth='sm'>
      <DialogTitle>New Snapshot</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            autoFocus margin="dense" id="description"
            label="Snapshot Name" fullWidth variant="standard"
            {...register("description", { required: true })}
            error={errors.description ? true : false}
          />

          {messageText &&
            <Box pt={4}>
              <Alert variant="filled" severity="error">{messageText}</Alert>
            </Box>
          }

          <Box pt={4}>
            <Stack
              direction={{ xs: 'row' }} spacing={2}
              justifyContent="flex-end"
              className="gas-button-stack"
            >
              <Button onClick={() => props.click(false)}>Cancel</Button>
              <Button type="submit" variant="contained">Create Snapshot</Button>
            </Stack>
          </Box>
        </Box>

      </DialogContent>
    </Dialog>
  );
}

export default SnapshotDetails;