import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Beginners from './assets/GAC_Beginner_FINAL_24_Apr.png';
import LinkList from '../../components/linkList';


const links = [
  {
    title: 'Recommendation tools; mods, gearing, etc.  Start with guides from ThePlaybook and Calvin Awesome.',
    description: 'Numerous mod and gearing guides',
    type: 'web',
    url: 'https://www.grandarenascience.com/resources/roster-development/'
  },
  {
    title: 'Counters; stats and data.  Go to the counters section of Grand Arena Science, and see swgoh.gg for more general stats.',
    description: 'SWGOH.GG',
    type: 'web',
    url: 'https://swgoh.gg/gac/counters/'
  },
  {
    title: 'Counters; gameplay videos.  Start at Grand Arena Science!  When you find a creator you like, follow them on YouTube or Twitch.',
    description: 'Grand Arena Science counters!',
    type: 'web',
    url: 'https://www.grandarenascience.com/counters/'
  },
  {
    title: 'Grand Arena Championship basics; points, rewards, design, etc.',
    description: 'SWGOH wiki for GAC',
    type: 'web',
    url: 'https://wiki.swgoh.help/wiki/Grand_Arena_Championships'
  },
  {
    title: 'Mods; team guides.  There are a number of guides from Grand Arena Science as well as from many other creators.  The best are included here.',
    description: 'Numerous guides in our Resources section.',
    type: 'web',
    url: 'https://www.grandarenascience.com/resources/guides/'
  },
  {
    title: 'Game knowledge; teams, interactions, etc.  This is a vast and detailed topic, check out the Meta section in Resources and stop by Grand Arena Science discord to learn more.',
    description: 'Grand Arena Science discord.  Ask for advice!',
    type: 'web',
    url: 'https://discord.grandarenascience.com'
  },
  {
    title: 'Mods: acquisition and management.  Start with the Building Your Foundation from ThePlaybook, then check out the other ones as your interest dicates.',
    description: 'Numerous mod guides',
    type: 'web',
    url: 'https://www.grandarenascience.com/resources/roster-development/'
  },
  {
    title: 'Roster Development: prioritization and GAC focus.  Start with the GAC 101: Developing a GAC Roster by Calvin Awesome (his whole series is great), then stop by discord to ask further questions and chat with experts.',
    description: 'Roster development for GAC',
    type: 'web',
    url: 'https://www.grandarenascience.com/resources/roster-development/'
  },
];

export default function BeginnersPage() {

  return (
    <Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            GAC for Beginners.
        </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            Beginner doesn't mean you have just started to play GAC; it refers more towards the seriousness of your approach towards the game mode.  Do you want to get better?  Do you want to start trying to reach Kyber regularly?  If so, this Overview is aimed at you!  GAC can be intimidating and time consuming - continue below to find recommendations on the most effective way to use your time to improve your GAC knowledge and ass-kickery. 
          </Typography>
        </Container>
      </Box>

      <Box py={4} bgcolor="background.paper">
        <Container maxWidth="md">
          <img src={Beginners} alt="GAC for Beginners; an Overview" />
        </Container>
      </Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Links.
          </Typography>
          <LinkList items={links} />
        </Container>
      </Box>

    </Box>
  );
}