import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';

import PlanTeams from './teams';
import PlanDrawer from './drawer';

const PlanHeader = (props) =>
  <Box className={classNames('gas-plan-zone__header', `gas-plan-zone__header--${props.type}`)}>
    <Typography variant="overline" component="p">{props.name}</Typography>
    <Typography variant="overline" component="p">{props.current}{props.max && `/${props.max}`}</Typography>
  </Box>;

const PlanZone = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [teamList, setTeams] = useState([]);
  const { units, teams, onZoneUpdate, id, type, headerBottom, name, max, disabled, attack, unitType, snapshotUnits } = props;

  useEffect(() => {    
    teams && setTeams(teams);
  }, [teams]);

  const toggleZone = () => {
    setShowDrawer(!showDrawer);
  };

  const onSave = (data) => {
    let updateData = [];
    data.forEach((d) => d.length > 0 && updateData.push(d));
    setTeams(updateData);
    onZoneUpdate(updateData, id, unitType);
    toggleZone();
  }

  return (
    <Box className={classNames('gas-plan-zone', `gas-plan-zone--${type}`)}>
      {!headerBottom &&
        <PlanHeader
          type={type} name={name}
          current={teamList.length} max={max}
        />
      }
      <Box className="gas-plan-zone__teams" onClick={!disabled ? toggleZone : undefined}>
        <PlanTeams
          type={type} toggle={toggleZone} teams={teamList} unitType={unitType}
          current={teamList.length} max={max} disabled={disabled}
        />
      </Box>
      {headerBottom &&
        <PlanHeader
          type={type} name={name}
          current={teamList.length} max={max}
        />
      }

      <Drawer
        anchor="bottom"
        open={showDrawer}
        onClose={toggleZone}
      >
        <PlanDrawer
          name={name} toggle={toggleZone} onSave={onSave} units={units} unitType={unitType}
          current={teamList.length} max={max} teams={teamList} attack={attack} snapshotUnits={snapshotUnits}
        />
      </Drawer>

    </Box>
  );
}

export default PlanZone;