import React, { useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';

import { getModSet, getModStat, getModSlot, getModValue } from '../../../utils/mods';

const ChangeMod = (props) => {
  const [modSet, changeModSet] = useState(null);
  const [modRarity, changeModRarity] = useState(null);
  const [modStat, changeModStat] = useState(null);

  const onModChange = () => {
    let setRarity = modRarity ? modRarity : props.data.pips;
    let statId = modStat ? modStat : props.data.primaryStat.unitStat;
    let mod = {
      ...props.data,
      set: modSet ? modSet : props.data.set,
      pips: setRarity,
      primaryStat: { unitStat: statId, value: getModValue(setRarity, statId) } 
    }
    props.changeMod && props.changeMod(mod);
    props.close && props.close();
  }

  return (
    <Dialog onClose={props.close} open onClick={(e) => e.stopPropagation()} fullWidth maxWidth='sm'>
      <DialogTitle>
        <Box className="gas-toon-image__title">Change Mod {getModSlot(props.data.slot)}
          <IconButton
            aria-label="close"
            className="gas-toon-image__close"
            onClick={props.close}
            size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ width: 360 }}>
        <Box pb={2}>
          <Typography variant="subtitle1">Mod Set</Typography>
          <ButtonGroup color="primary">
            <Button variant={modSet === 1 && 'contained'} onClick={() => changeModSet(1)}>{getModSet(1)}</Button>
            <Button variant={modSet === 3 && 'contained'} onClick={() => changeModSet(3)}>{getModSet(3)}</Button>
          </ButtonGroup>
        </Box>
        <Box pb={2}>
          <Typography variant="subtitle1">Mod Rarity</Typography>
          <ButtonGroup color="primary">
            <Button variant={modRarity === 5 && 'contained'} onClick={() => changeModRarity(5)}>5</Button>
            <Button variant={modRarity === 6 && 'contained'} onClick={() => changeModRarity(6)}>6</Button>
          </ButtonGroup>
        </Box>
        {!(props.data.slot === 1 || props.data.slot === 3) &&
          <Box pb={2}>
            <Typography variant="subtitle1">Primary Stat</Typography>
            <ButtonGroup color="primary">
              <Button variant={modStat === 49 && 'contained'} onClick={() => changeModStat(49)}>{getModStat(49)}</Button>
              <Button variant={modStat === 56 && 'contained'} onClick={() => changeModStat(56)}>{getModStat(56)}</Button>
              <Button variant={modStat === 55 && 'contained'} onClick={() => changeModStat(55)}>{getModStat(55)}</Button>
            </ButtonGroup>
          </Box>
        }
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={props.close}>
          Cancel
        </Button>
        <Button onClick={onModChange} color="primary" variant="contained">
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangeMod;