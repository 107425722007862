import { createSlice } from '@reduxjs/toolkit';

export const snapSlice = createSlice({
    name: 'snapshots',
    initialState: [],
    reducers: {
        addSnapshots: (state, { payload }) => {
            return payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { addSnapshots } = snapSlice.actions;

export default snapSlice.reducer;