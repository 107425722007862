import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

const CompareProfile = (props) => {
  const { name, code } = props;

  return (
    <Box className="gas-compare-profile">
      <Box pb={1}>
        {name && <Avatar className="gas-compare-avatar">{name.charAt(0)}{name.charAt(1)}</Avatar>}
      </Box>
      <Typography variant="body1" style={{wordBreak: 'break-word'}}>{name}</Typography>
      <Typography variant="caption">{code}</Typography>
    </Box>
  );
}

export default CompareProfile;