import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import DaMit from './assets/DaMitigation_FINAL_12May.png';
import LinkList from '../../components/linkList';


const links = [
    {
      title: 'Damage Mitigation Simulator',
      description: 'Damage Mitigation Simulator',
      type: 'web',
      url: 'https://www.grandarenascience.com/damage'
    },
    {
      title: 'Join discord to ask questions and talk to experts',
      description: 'Grand Arena Science discord',
      type: 'web',
      url: 'https://discord.gg/29yYq2JT57'
    },
];


export default function DamageMitigationPage() {

    return (
        <Box>
            <Box py={4}>
                <Container maxWidth="md">
                    <Typography align="center" variant="h4" component="h2" gutterBottom>
                        Damage Mitigation.
                    </Typography>
                    <Typography align="left" variant="h6" component="p" gutterBottom>
                        How incoming damage is mitigated is an often misunderstood topic within SWGOH.  One of the primary reasons is the primary mitigation stat (Defense; this is different than the Armor and Resistance % stats) is not actually shown in the game, so understanding exactly how everything works together is complicated.  The Overview below tries to explain this as clearly as possible, including a couple of examples.

                        The most important thing you should consider for your own GAC performance is the extreme value of Defense mods, mainly the sets but also the primary stat.  Post g13 these are the best mitigation option for 99% of all characters.

                        There is a link to the calculator below the JPG where you can simulate different options for your own characters.  Check it out!  Then stop by our discord if you have any questions or would like to chat about it further.
                    </Typography>
                </Container>
            </Box>
    
            <Box py={4} bgcolor="background.paper">
                <Container maxWidth="md">
                    <img src={DaMit} alt="Damage Mitigation Overview" />
                </Container>
            </Box>
    
            <Box py={4}>
                <Container maxWidth="md">
                    <Typography align="center" variant="h4" component="h2" gutterBottom>
                        Links.
                    </Typography>
                    <LinkList items={links} />
                </Container>
            </Box>
  
        </Box>
    );
}

