export const removeDuplicates = (arr) => {
    let uniqueArray = Array.from(new Set(arr));
    let sortArray = uniqueArray.sort((a, b) => a.name.localeCompare(b.name));
    return sortArray;
};

export const buildCreatorList = (arr) => {
    let getCreators = arr.map(c => c.creator)
    let uniqueArray = Array.from(new Set(getCreators));
    let sortArray = uniqueArray.sort((a, b) => a.localeCompare(b));
    return sortArray;
}