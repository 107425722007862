import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';

import PlayCountdown from '../../components/play/countdown';
import PlanMap from '../../components/plan/map';

const useStyles = makeStyles((theme) => ({
  altBg: {
    background: theme.palette.grey['900']
  },
  heroMeta: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const PlayDefense = (props) => {
  const classes = useStyles();

  return (
    <Box>

      <Container maxWidth="md" className={classes.altBg}>
        <Box py={2}>
          <Typography align="center" variant="h4" component="h1">Set Defense</Typography>
          <Box pt={2} className={classes.heroMeta}>
            <Typography align="left" variant="body1" component="p">vs DinGame</Typography>
            <PlayCountdown date="2023-03-21T22:00:00" title="Ends in" status="defense" meta />
          </Box>
        </Box>
      </Container>

      <PlanMap defense />

    </Box>
  );
}

export default PlayDefense;