import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

import { convertGp } from '../../utils';

const Stat = (props) => <Grid container spacing={4} className="gas-compare-summary__grid">
  <Grid item xs className="gas-compare-summary__left">{props.left}</Grid>
  <Grid item xs="auto">
    <Chip label={props.label} size="small" />
  </Grid>
  <Grid item xs className="gas-compare-summary__right">{props.right}</Grid>
</Grid>;

const CompareSummary = (props) => {
  const { userStats } = props;
  const [full, toggleFull] = useState(false);
  
  return (
    <Box className="gas-compare-summary">
      {userStats &&
        <>
          <Stat label="GP" left={convertGp(userStats.galactic_power)} right="8.15M" />
          <Stat label="GLs" left={userStats.gls.length} right="5" />
          <Stat label="Zetas" left={userStats.zetas} right="186" />
          <Stat label="Omicrons" left={userStats.omicrons} right="2" />
          <Stat label="G13" left={userStats.g13} right="138" />
          <Stat label="R5+" left={userStats.r5_plus} right="79" />
        </>
      }

      {full &&
        <>
          <Divider style={{ marginBottom: '1rem' }}>
            <Chip label="Gear &amp; Relics" color="primary" variant="outlined" />
          </Divider>
          <Stat label="G12" left={userStats.g12} right="79" />
          <Stat label="G13" left={userStats.g13} right="138" />
          <Stat label="Relics" left={userStats.relic_levels} right="79" />
          <Stat label="R5+" left={userStats.r5_plus} right="79" />
          <Stat label="R5" left={userStats.r5} right="79" />
          <Stat label="R6" left={userStats.r6} right="79" />
          <Stat label="R7" left={userStats.r7} right="79" />
          <Stat label="R8" left={userStats.r8} right="79" />
          <Stat label="R9" left={userStats.r9} right="79" />

          <Divider style={{ marginBottom: '1rem' }}>
            <Chip label="Mods" color="primary" variant="outlined" />
          </Divider>
          <Stat label="6E" left={userStats.mod_6_e} right="79" />
          <Stat label="15+ speed" left={userStats.mod_15_plus} right="79" />
          <Stat label="20+ speed" left={userStats.mod_20_plus} right="79" />
          <Stat label="25+ speed" left={userStats.mod_25_plus} right="79" />
          <Stat label="Top 25" left={userStats.mod_top_25.toFixed(2)} right="79" />
          <Stat label="Top 500" left={userStats.mod_top_500.toFixed(2)} right="79" />
        </>
      }
      <Divider style={{ marginTop: '1rem' }}>
        <Chip label={`${full ? 'Hide' : 'View'} Full Comparison`} color="secondary" onClick={() => toggleFull(!full)} />
      </Divider>
    </Box>
  );
}

export default CompareSummary;