import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1400,
  }
}));

const GasLoading = (props) => {
  const classes = useStyles();
  const loading = useSelector((state) => state.loading);

  return (
    <Backdrop open={loading} className={classes.backdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default GasLoading;