import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import YouTubeIcon from '@mui/icons-material/YouTube';
import HttpIcon from '@mui/icons-material/Http';
import RedditIcon from '@mui/icons-material/Reddit';

const useStyles = makeStyles({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  area: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  media: {
    height: 0,
    paddingTop: '56.25%'
  },
  footer: {
    display: 'flex',
    marginTop: 'auto',
    justifyContent: 'space-between',
    fontSize: 14,
    paddingLeft: 16,
    paddingRight: 16,
    width: '100%'
  },
  link: {
    textTransform: 'uppercase',
    fontWeight: 700
  }
});

const getAvatarBg = (cat) => {
  let bg = '#000000';
  if (cat.includes('youtu')) {
    bg = '#ff0000';
  } else if (cat.includes('discord')) {
    bg = '#5865F2';
  } else if (cat.includes('grandarenascience')) {
    bg = '#648dae';
  } else if (cat.includes('docs') || cat.includes('drive')) {
    bg = '#34a853';
  } else if (cat.includes('reddit')) {
    bg = '#ff4500';
  }
  return bg;
};

const AvatarIcon = (cat) => {
  let icon = <HttpIcon />;
  if (cat.includes('youtu')) {
    icon = <YouTubeIcon />;
  } else if (cat.includes('discord')) {
    icon = <svg role="img" height="24" width="24" viewBox="0 0 24 24"><title>Discord</title><path fill="#ffffff" d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z" /></svg>;
  } else if (cat.includes('grandarenascience')) {
    icon = <svg role="img" height="24" width="24" viewBox="0 0 768 768"><title>Grand Arena Science</title><path fill="#ffffff" d="M633 589.5l-184.5-247.5v-133.5l42-54q7.5-9 3-18t-15-9h-189q-10.5 0-15 9t3 18l42 54v133.5l-184.5 247.5q-9 10.5-7.5 21.75t10.5 20.25 22.5 9h447q13.5 0 22.5-9t10.5-20.25-7.5-21.75z"/></svg>;
  } else if (cat.includes('docs') || cat.includes('drive')) {
    icon = <svg role="img" height="24" width="24" viewBox="0 0 640 640"><title>Google Sheets</title><path fill="#ffffff" d="M512 32h-384c-17.696 0-32 14.304-32 32v512c0 17.664 14.304 32 32 32h384c17.696 0 32-14.336 32-32v-512c0-17.664-14.304-32-32-32zM480 256h-192v288h-32v-288h-96v-32h96v-128h32v128h192v32z"/></svg>;
  } else if (cat.includes('reddit')) {
    icon = <RedditIcon />;
  }
  return icon;
};

const ResourceCard = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea onClick={() => window.open(props.link)} className={classes.area}>
        <CardHeader
          avatar={
            <Avatar style={{ background: getAvatarBg(props.link), color: 'white' }}>
              {AvatarIcon(props.link)}
            </Avatar>
          }
          title={props.title}
          subheader={props.category}
        />
        {props.image &&
          <CardMedia
            className={classes.media}
            image={props.image}
          />
        }
        <CardContent>
          {props.description && 
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
              {props.description}
            </Typography>
          }
          {props.notes && 
            <Typography variant="body2" color="textSecondary" component="p">
              "{props.notes}""
            </Typography>
          }
        </CardContent>
        <CardActions disableSpacing className={classes.footer}>
          <Box>
            {props.creator}
          </Box>
          <Box>
            {props.date}
          </Box>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}

export default ResourceCard;