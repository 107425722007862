import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import PlanDetails from './details';

const PlanHeader = (props) => {
  const { data, onClone, updatePlan, snapshot, onDelete } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [noSnaps, setNoSnaps] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const menuOpen = Boolean(anchorEl);

  const handleUpdateClose = useCallback(() => {
    if (!noSnaps) {
      setOpen(false);
      handleMenuClose();
    }
  }, [noSnaps]);

  useEffect(() => {
    if (data && !data.roster_snapshot_id) {
      setNoSnaps(true);
      setOpen(true);
      !noSnaps && handleUpdateClose();
    }
  }, [data, noSnaps, handleUpdateClose]);

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClone = () => {
    handleUpdateClose();
    onClone && onClone();
  };

  return (
    <Box className="gas-plan-header" py={2}>
      <Container maxWidth="md" className="gas-plan-header__content">

        <Box className="gas-plan-header__details">
          {data &&
            <>
              <Typography variant="h5" component="h1">{data.description}</Typography>
              <Typography variant="caption" component="p" >
                {data.format === 'FIVEVFIVE' ? '5v5' : '3v3'} | {(data.play_style === 'defense' || data.play_style === 'offense') && 'Max'} {data.play_style && `${data.play_style.charAt(0).toUpperCase()}${data.play_style.slice(1)}`}
              </Typography>
              <Typography variant="caption" component="p" >
                Last updated: {format(new Date(data.updated_at), 'MMM	dd, yyyy')}
              </Typography>
            </>
          }
        </Box>

        <Box className="gas-plan-header__menu" pl={4}>
          <IconButton
            onClick={handleMenuClick} id="editBtm"
            aria-controls={open ? 'editMenu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>

        <Menu
          open={menuOpen} onClose={handleMenuClose}
          id="editMenu" anchorEl={anchorEl}
        >
          <MenuItem onClick={() => setOpen(true)}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleClone}>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Clone</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => setShowDelete(true)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>

      </Container>

      {data &&
        <PlanDetails
          updatePlan={updatePlan} snapshotData={snapshot} noSnaps={noSnaps}
          data={data} isEdit click={handleUpdateClose} open={open} setNoSnaps={setNoSnaps}
        />
      }

      <Dialog
        open={showDelete}
        onClose={() => setShowDelete(false)}
        aria-labelledby="dialog-delete"
        aria-describedby="dialog-delete-description"
        fullWidth maxWidth='sm'
      >
        <DialogTitle id="dialog-delete">Delete Plan</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-delete-description">
            Are you sure want to delete '{data && data.description}' plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDelete(false)}>Cancel</Button>
          <Button onClick={onDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

export default PlanHeader;