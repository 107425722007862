import { createSlice } from '@reduxjs/toolkit';

export const statSlice = createSlice({
    name: 'stats',
    initialState: [],
    reducers: {
        setStats: (state, { payload }) => {
            return payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setStats } = statSlice.actions;

export default statSlice.reducer;