import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';

const PlayHeader = (props) => {

  const useStyles = makeStyles((theme) => ({
    heroImage: {
      maxWidth: 128,
      margin: props.active ? 0 :'2rem auto',
      display: 'block'
    }
  }));
  
  const classes = useStyles();

  return (
    <Box className="gas-play-header" py={props.active ? 0 : 2}>
      <img src={props.image} alt="GAC Buddy" className={classes.heroImage} />
    </Box>
  );
}

export default PlayHeader;