import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Toon from '../toon';

const season3v3 = '27';
const season5v5 = '26';

const CounterFilters = (props) => {
  const [toons, setToons] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(false);
  const [metaParams, setMetaParams] = useState([]);

  useEffect(() => {
    props.data && setToons(props.data);
  }, [props.data]);

  const addToons = (e, v) => {
    let updateToons = [
      ...toons, v
    ]
    setToons(updateToons);
    props.addToons && props.addToons(updateToons, props.index, v);
    metaQuery(updateToons);
  }

  const removeToons = () => {
    let updateToons = [...toons].slice(0, -1);
    setToons(updateToons);
    props.addToons && props.addToons(updateToons, props.index, null, toons.slice(-1)[0]);
    metaQuery(updateToons);
  }

  const onSearchBy = (v) => {
    props.changeSearchby(v);
    if (v !== props.searchBy) {
      setToons([]);
    }
  }

  const metaQuery = (data) => {
    let query = data.length > 1 && data.map(t => `&d_unit=${t.base_id}`);
    query.length > 0 && query.shift();
    setMetaParams(query.length > 0 ? query.join('') : []);
  }

  const groupClick = () => {
    props.teamSelection(toons);
    setSelectedGroup(!selectedGroup)
  }

  const RenderToon = (props) => <Box className={classNames('gas-filters__toon', toons.length < props.id && 'gas-filters__toon--disabled', toons.length === props.id && 'gas-filters__toon--enabled', props.noWrap && 'gas-filters__toon--nowrap')}>
    <Toon
      src={toons[props.id] && toons[props.id].base_id}
      unitId={toons[props.id] && toons[props.id].unit_id}
      add={!props.noAdd} list={props.toonsOnly ? props.toonList : props.leaders}
      onAdd={addToons} selected={selectedGroup} type={props.type}
      remove={!props.noRemove && toons.length === props.id + 1} onRemove={removeToons}
      gear={toons[props.id] && toons[props.id].gear_level} 
      relic={toons[props.id] && toons[props.id].relic_level} 
      speed={toons[props.id] && toons[props.id].speed} 
      zeta={toons[props.id] && toons[props.id].zeta} 
      omicron={toons[props.id] && toons[props.id].omicron} 
      showStats={props.showStats}
    />
  </Box>;

  return (
    <Box className="gas-filters">
      {!props.toonsOnly &&
        <Box className="gas-filters__group">
          <ButtonGroup>
            <Button
              onClick={() => onSearchBy('def')} color="primary"
              variant={props.searchBy === 'def' ? 'contained' : 'outlined'}
            >Defense</Button>
            <Button
              onClick={() => onSearchBy('atk')} color="primary"
              variant={props.searchBy === 'atk' ? 'contained' : 'outlined'}
            >Offense</Button>
          </ButtonGroup>
        </Box>
      }
      {!props.toonsOnly &&
        <Box className="gas-filters__group">
          <ButtonGroup>
            <Button component={NavLink} variant={props.type === '5v5'? 'contained' : 'outlined'}to="/counters/">5v5</Button>
            <Button component={NavLink} variant={props.type === '3v3'? 'contained' : 'outlined'} to="/counters/3v3/">3v3</Button>
            <Button component={NavLink} variant={props.type === 'fleet'? 'contained' : 'outlined'} to="/counters/fleet/">Ships</Button>
          </ButtonGroup>
        </Box>
      }
      <Box className={classNames('gas-filters__group', selectedGroup && 'gas-filters__group--selected', props.teamSelection && 'gas-filters__group--click', props.noWrap && 'gas-filters__group--nowrap')} onClick={props.teamSelection && groupClick}>
        <RenderToon id={0} {...props} />
        <RenderToon id={1} {...props} />
        <RenderToon id={2} {...props} />
        {(props.type === '5v5' || props.type === 'fleet') &&
          <RenderToon id={3} {...props} />
        }
        {(props.type === '5v5' || props.type === 'fleet') &&
          <RenderToon id={4} {...props} />
        }
        {props.type === 'fleet' &&
          <RenderToon id={5} {...props} />
        }
        {props.type === 'fleet' &&          
          <RenderToon id={6} {...props} />
        }
        {props.type === 'fleet' &&        
          <RenderToon id={7} {...props} />
        }
        {props.banners && props.winRate &&
          <Box className="gas-filters__meta">
            <Box className="gas-filters__banners">{props.banners}</Box>
            <Box className="gas-filters__winrate">{props.winRate}%</Box>
          </Box>
        }
      </Box>
      {props.type !== 'fleet' && toons.length > 0 && !props.toonsOnly &&
        <Box className="gas-filters__group">
          <Button
            onClick={() => window.open(`https://swgoh.gg/gac/${props.searchBy === 'def' ? 'counters' : 'who-to-attack'}/${toons[0].base_id}/?season=${props.type === '5v5' ? season5v5 : season3v3}${props.searchBy === 'def' && metaParams ? metaParams : ''}`)} color="primary"
            endIcon={<OpenInNewIcon />}
          >Meta Report</Button>
        </Box>
      }
    </Box>
  );
}

export default CounterFilters;