import axios from 'axios';

export const setAuthToken = token => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    else
        delete axios.defaults.headers.common['Authorization'];
}

axios.interceptors.response.use(response => {
    return response;
}, err => {
    return new Promise((resolve, reject) => {
        const originalReq = err.config;
        if ( err.response.status === 401 && err.config && !err.config.__isRetryRequest )
        {
            
            /*localStorage.removeItem('token');
            localStorage.removeItem('refresh');
            window.location.replace('/login/');*/
            originalReq._retry = true;

            let res = fetch('https://gasapi.info/api/v1/auth/refresh-token', {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Device': 'device',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                redirect: 'follow',
                referrer: 'no-referrer',
                body: JSON.stringify({
                    refresh_token: localStorage.getItem("refresh")
                }),
            }).then(res => res.json()).then(res => {
                console.log(res);
                localStorage.getItem("token")
                this.setSession({token: res.token, refresh: res.refresh});
                originalReq.headers['Authorization'] = `Bearer ${res.token}`;

                return axios(originalReq);
            });

            resolve(res);
        }


        return Promise.reject(err);
    });
});