import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns'

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import ShieldIcon from '@mui/icons-material/Shield';

import BuddyHeader from '../images/buddy-header.jpg'
import { toggleLoading } from '../../slices/loading';
import HistoryTeam from '../../components/history/team';
import Alert from '@mui/material/Alert';

const ReviewPage = () => {
  const [history, setHistory] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [style, setStyle] = useState('Defense');
  const [week, setWeek] = useState('');
  const [round, setRound] = useState(1);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    const loadHistory = (id) => {
      dispatch(toggleLoading(true));
      axios.get(`https://gasapi.info/api/v1/gac/history-data/${id}`)
        .then(response => {
          setHistoryData(response.data.data);
          dispatch(toggleLoading(false));
        })
        .catch(err => {
          dispatch(toggleLoading(false));
          console.log(err.response ? err.response.data.message : err);
        });
    };

    profile.ally_code && loadHistory(profile.ally_code);

  }, [dispatch, profile]);

  const getHistory = (d, r) => {
    dispatch(toggleLoading(true));
    axios.get(`https://gasapi.info/api/v1/gac/history/${profile.ally_code}?date_round=${d}&round=${r}`)
      .then(response => {
        setHistory(response.data.data);
        setRound(r);
        dispatch(toggleLoading(false));
      })
      .catch(err => {
        dispatch(toggleLoading(false));
        console.log(err.response ? err.response.data.message : err);
      });
  };

  const weekChange = (e) => {
    setWeek(e.target.value);
    getHistory(format(new Date(e.target.value.date_round), 'yyyy-MM-dd'), 1);
  };

  const roundChange = (v) => {
    setRound(v);
    getHistory(format(new Date(week.date_round), 'yyyy-MM-dd'), v);
  };

  const styleChange = (v) => {
    setStyle(v);
  };

  return (
    <Box>
      <Box textAlign="center">
        <img src={BuddyHeader} alt="GAC Buddy" />
      </Box>
      <Box pt={2} pb={4}>
        <Container maxWidth="lg">
          <Typography align="center" variant="h4" component="h1" gutterBottom style={{ marginBottom: 24 }}>
            Your GAC History
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <FormControl variant="filled" sx={{ width: '100%' }}>
                <InputLabel id="weekLabel">Select Week</InputLabel>
                <Select
                  labelId="weekLabel"
                  id="weekSelect"
                  value={week}
                  onChange={weekChange}
                >
                  {historyData.length > 0 && historyData.map((h, i) =>
                    <MenuItem key={i} value={h}>{format(new Date(h.date_round), 'MM/dd/yyyy')}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={9} className='gas-history-rounds'>
              {week && week.rounds && week.rounds.length > 0 ?
                <ButtonGroup fullWidth className='gas-history-rounds__select'>
                  {week.rounds.map((r, i) =>
                    <Button
                      key={i} variant={round === i + 1 ? 'contained' : 'outlined'}
                      onClick={() => roundChange(i + 1)}
                    >{r.opponent_name}</Button>
                  )}
                </ButtonGroup>
                :
                <Alert className='gas-history-rounds__alert' severity="info" variant='outlined' icon={<ArrowUpwardIcon />}>Select week to begin</Alert>
              }
            </Grid>
          </Grid>
        </Container>
      </Box>

      {week && week.rounds && week.rounds.length > 0 &&
        <Box pb={4}>
          <Container maxWidth="xl">
            <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <Chip label={`Round ${round}`} />
            </Divider>
            <Typography variant="h5" align="center" component="h2" gutterBottom>
              {week.rounds[round - 1].opponent_name} ({week.rounds[round - 1].opponent_ally_code})
            </Typography>

            <Box mt={3} mb={3} textAlign="center">
              <ButtonGroup>
                <Button
                  variant={style === 'Defense' ? 'contained' : 'outlined'}
                  onClick={() => styleChange('Defense')} color="secondary"
                >Defense</Button>
                <Button
                  variant={style === 'Attack' ? 'contained' : 'outlined'}
                  onClick={() => styleChange('Attack')} color="secondary"
                >Attack</Button>
              </ButtonGroup>
            </Box>

            <Box className='gas-histor-versus'>
              <Box className='gas-histor-versus__tag'>
                <GpsFixedIcon className='gas-histor-versus__icon' /> {style === 'Defense' ? week.rounds[round - 1].opponent_name : profile.game_name} attacks
              </Box>
              <Box>-</Box>
              <Box className='gas-histor-versus__tag'>
                <ShieldIcon className='gas-histor-versus__icon' /> {style === 'Attack' ? week.rounds[round - 1].opponent_name : profile.game_name} defends
              </Box>
            </Box>


            {history && history.gacHistory.length > 0 ? history.gacHistory.reverse().map((h, i) =>
              h.style === style && <HistoryTeam data={h} key={i} />
            )
              :
              <Alert severity="info" variant='outlined'>No data available.</Alert>
            }

          </Container>
        </Box>
      }

    </Box>
  );
}

export default ReviewPage;