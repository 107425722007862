import React from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';

import { convertGp } from '../../utils';


const CompareBracket = (props) => {
  const { data } = props;

  const getRowData = (field) => {
    let array = [];
    data.forEach(d => array.push(d[field]));
    return array;
  }

  const RenderRow = (props) => <TableRow>
    <TableCell component="th" scope="row">{props.label}</TableCell>
    {getRowData(props.field).map((d, i) =>
      <TableCell key={i}>{props.format ? props.format(d) : d}</TableCell>
    )}
  </TableRow>;

  return (
    <Box className="gas-compare-bracket">

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">
                Player
              </TableCell>
              {data && data.length > 0 && getRowData('user').map((d, i) =>
                <TableCell key={i}>
                  <Avatar>{d.game_name.charAt(0)}{d.game_name.charAt(1)}</Avatar>
                  {d.game_name}
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          {data && data.length > 0 &&
            <TableBody>
              <RenderRow label="GP" field="galactic_power" format={(v) => convertGp(v)} />
              <RenderRow label="GLS" field="gls" format={(v) => v.length} />
              <RenderRow label="Zetas" field="zetas" />
              <RenderRow label="Omicrons" field="omicrons" />

              <TableRow>
                <TableCell style={{ background: '#121212' }} variant="head" colSpan="9" align="center">Gear &amp; Relics</TableCell>
              </TableRow>
              <RenderRow label="G13" field="g13" />
              <RenderRow label="G12" field="g12" />
              <RenderRow label="Relics" field="relic_levels" />
              <RenderRow label="R5+" field="r5_plus" />
              <RenderRow label="R5" field="r5" />
              <RenderRow label="R7" field="r7" />
              <RenderRow label="R8" field="r8" />
              <RenderRow label="R9" field="r9" />

              <TableRow>
                <TableCell style={{ background: '#121212' }} variant="head" colSpan="9" align="center">Mods</TableCell>
              </TableRow>
              <RenderRow label="6E" field="mod_6_e" />
              <RenderRow label="15+ Speed" field="mod_15_plus" />
              <RenderRow label="20+ Speed" field="mod_20_plus" />
              <RenderRow label="25+ Speed" field="mod_25_plus" />
              <RenderRow label="Top 25 Speed" field="mod_top_25" format={(v) => v.toFixed(2)} />
              <RenderRow label="Top 50 Speed" field="mod_top_500" format={(v) => v.toFixed(2)} />
            </TableBody>
          }
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CompareBracket;