import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { toggleLoading } from '../../slices/loading';
import { setOpponent } from '../../slices/opponent';
import Alert from '@mui/material/Alert';

const SelectOpponent = (props) => {
  const { className, chooseOpponent, pt, mb } = props;
  const [bracketData, setBracketData] = useState([]);
  const opponent = useSelector((state) => state.opponent);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadBracket = () => {
      dispatch(toggleLoading(true));
      axios.get('https://gasapi.info/api/v1/gac/get-current-gac-bracket-info')
        .then(response => {
          setBracketData(response.data.data);
          dispatch(toggleLoading(false));
        })
        .catch(err => {
          dispatch(toggleLoading(false));
          console.log(err.response ? err.response.data.message : err);
        });
    };
    loadBracket();

  }, [dispatch]);

  const opponentChange = (e) => {
    dispatch(setOpponent(JSON.parse(e.target.value)));
    chooseOpponent && chooseOpponent(e.target.value);
  };

  return (
    <Box className={className} pt={pt} mb={mb}>
      <FormControl variant="filled" sx={{ width: '100%', margin: '0 0 16px 0' }}>
        <InputLabel id="weekLabel">Select Opponent</InputLabel>
        {<Select
          labelId="weekLabel"
          id="weekSelect"
          value={bracketData.length > 0 && opponent ? JSON.stringify(opponent) : ''}
          onChange={opponentChange}
        >
          {bracketData.length > 0 && bracketData.map((h, i) =>
            <MenuItem key={i} value={JSON.stringify(h)}>{h.opponent_in_game_name}</MenuItem>
          )}
        </Select>}
      </FormControl>
      {!opponent &&
        <Alert severity="info" variant='outlined' icon={<ArrowUpwardIcon />}>Select opponent to begin</Alert>
      }
    </Box>
  );
}

export default SelectOpponent;