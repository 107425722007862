import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';

import PlanHeader from '../../components/plan/header';
import PlanMap from '../../components/plan/map';
import { toggleLoading } from '../../slices/loading';
import { showToast } from '../../slices/toast';
import { zoneUpdateMap } from '../../components/plan/utils';

const PlanManagePage = (props) => {
  const [plan, setPlan] = useState(null);
  const [snapshot, setSnapshot] = useState(null);
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch(toggleLoading(true));
    axios.delete(`https://gasapi.info/api/v1/plan/${props.match.params.id}`)
      .then(response => {
        dispatch(toggleLoading(false));
        props.history.push('/plan/');
        dispatch(showToast({ show: true, message: 'Plan deleted succesfully.', severity: 'success' }));
      })
      .catch(err => {
        dispatch(toggleLoading(false));
        console.log(err.response ? err.response.data.message : err);
      });
  }

  const onClone = () => {
    dispatch(toggleLoading(true));
    let updateZones = [];
    plan.zones.length > 0 && plan.zones.forEach((p) => updateZones.push({ zone_id: p.zone_id, teams: zoneUpdateMap(p.teams) }));
    let savePlan = {
      description: `Copy of ${plan.description}`,
      play_style: plan.play_style,
      format: plan.format,
      roster_snapshot_id: plan.roster_snapshot_id,
      zones: updateZones
    }
    axios.post('https://gasapi.info/api/v1/plan/', savePlan)
      .then(response => {
        dispatch(toggleLoading(false));
        props.history.push(`/plan/${response.data.data.plan_id}/`);
        dispatch(showToast({ show: true, message: 'Plan cloned succesfully.', severity: 'success' }));
      })
      .catch(err => {
        dispatch(toggleLoading(false));
        console.log(err.response ? err.response.data.message : err);
      });
  }

  return (
    <Box>
      <PlanHeader data={plan} snapshot={snapshot} updatePlan={setPlan} onDelete={onDelete} onClone={onClone} />
      <PlanMap updatePlan={setPlan} updateSnapshot={setSnapshot} planId={props.match.params.id} />
    </Box>
  );
}

export default PlanManagePage;