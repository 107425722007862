import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';

import RouteGuard from './components/auth/guard';
import GasLoading from './components/loading';
import CountersPage from './pages/counters';
import HomePage from './pages/home';
import LoginPage from './pages/profile/login';
//import RegisterPage from './pages/profile/register';
import SnapshotsPage from './pages/profile/snapshots';
import ForgotPasswordPage from './pages/profile/forgot-password';
import ResetPasswordPage from './pages/profile/reset-password';
import DamagePage from './pages/damage';
import ResourcesPage from './pages/resources';
import BountyHuntersPage from './pages/overviews/bounty-hunters';
import ImperialTroopersPage from './pages/overviews/imperial-troopers';
import BeforeEachFightPage from './pages/overviews/before-each-fight';
import BeginnersPage from './pages/overviews/beginners';
import ScoutingPage from './pages/overviews/scouting';
import DamageMitigationPage from './pages/overviews/damit';
import MatchmakingPage from './pages/overviews/matchmaking';
import LeaderSplitsPage from './pages/overviews/leadersplits';
import SoloGLPage from './pages/overviews/sologl';
import ComingsoonPage from './pages/comingsoon';
import ContactPage from './pages/contact';
import PrivacyPage from './pages/privacy';
import BuddyPage from './pages/buddy';
import PlanPage from './pages/buddy/plan';
import ReviewPage from './pages/buddy/review';
import BracketPage from './pages/buddy/bracket';
import PlayPage from './pages/buddy/play';
import PlayDefense from './pages/buddy/defense';
import PlayAttack from './pages/buddy/attack';
import PlanManagePage from './pages/buddy/manage';
import Navigation from './components/navigation';
import { setAuthToken } from './utils';
import GasToast from './components/snackbar';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(0, 0, 3, 0),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://grandarenascience.com/">
        GrandArenaScience.com
      </Link>{' '}
      {new Date().getFullYear()}
      {' __ '}
      <Link color="inherit" href="https://www.grandarenascience.com/privacy/">
        Privacy Policy
      </Link>
    </Typography>
  );
}

export default function App() {
  const classes = useStyles(); 
  const token = localStorage.getItem("token");
  if (token) {
      setAuthToken(token);
  }

  return (
    <Router>
      <Box className="gas-page">
        <Navigation />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/login/">
            <LoginPage />
          </Route>
          {/*<Route exact path="/register/">
            <RegisterPage />
  </Route>*/}
          <Route exact path="/forgot-password/">
            <ForgotPasswordPage />
          </Route>
          <Route path="/reset-password/">
            <ResetPasswordPage />
          </Route>
          <RouteGuard exact path="/buddy/" component={BuddyPage} />
          <RouteGuard exact path="/snapshots/" component={SnapshotsPage} />
          <RouteGuard exact path="/review/" component={ReviewPage} />
          <RouteGuard exact path="/plan/" component={PlanPage} />
          <RouteGuard exact path="/plan/:id/" component={PlanManagePage} />
          <RouteGuard exact path="/play/" component={PlayPage} />
          <RouteGuard exact path="/bracket/" component={BracketPage} />
          <RouteGuard exact path="/defense/" component={PlayDefense} />
          <RouteGuard exact path="/attack/" component={PlayAttack} />
          <RouteGuard exact path="/buddy/" component={BuddyPage} />
          <Route exact path="/counters/">
            <CountersPage type="5v5" />
          </Route>
          <Route exact path="/counters/3v3/">
            <CountersPage type="3v3" />
          </Route>
          <Route exact path="/counters/fleet/">
            <CountersPage type="fleet" />
          </Route>
          <Route exact path="/resources/" render={(props) => <ResourcesPage {...props} />} />
          <Route exact path="/resources/browse/:type/" render={(props) => <ResourcesPage {...props} />} />
          {/*<Route exact path="/resources/roster-development/">
            <RosterPage />
          </Route>
          <Route exact path="/resources/guides/">
            <GuidesPage />
          </Route>
          <Route exact path="/resources/meta/">
            <MetaPage />
          </Route>
          <Route exact path="/resources/tools/">
            <ToolsPage />
          </Route>
          */}
          <Route exact path="/resources/bounty-hunters/">
            <BountyHuntersPage />
          </Route>
          <Route exact path="/resources/imperial-troopers/">
            <ImperialTroopersPage />
          </Route>
          <Route exact path="/resources/before-each-fight/">
            <BeforeEachFightPage />
          </Route>
          <Route exact path="/resources/scouting/">
            <ScoutingPage />
          </Route>
          <Route exact path="/resources/beginners/">
            <BeginnersPage />
          </Route>
          <Route exact path="/resources/damit/">
            <DamageMitigationPage />
          </Route>
          <Route exact path="/resources/matchmaking/">
            <MatchmakingPage />
          </Route>
          <Route exact path="/resources/sologl/">
            <SoloGLPage />
          </Route>
          <Route exact path="/resources/leadersplits/">
            <LeaderSplitsPage />
          </Route>
          <Route exact path="/comingsoon/">
            <ComingsoonPage />
          </Route>    
          <Route exact path="/contact/">
            <ContactPage />
          </Route> 
          <Route exact path="/privacy/">
            <PrivacyPage />
          </Route>     
          <Route exact path="/damage/">
            <DamagePage />
          </Route>     
        </Switch>

        <Divider className={classes.divider} />
        <Box pb={3}>
          <Copyright />
          <GasLoading />
          <GasToast />
        </Box>
      </Box>
    </Router>
  );
}