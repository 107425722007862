import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import SortIcon from '@mui/icons-material/Sort';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';

import CounterFilters from '../counters/filters';
import { times } from '../../utils';
import { toggleLoading } from '../../slices/loading';
import { getUnit, getSnapUnit } from '../../utils';
import GasMenu from '../menu';

const PlanDrawer = (props) => {
  const [teamLists, setTeams] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [selected, setSelected] = useState([]);
  const [playStyle, setPlayStyle] = useState({ label: 'Win', val: 'win' });
  const [gearLevel, setGearLevel] = useState({ label: 'Opponent', val: 'auto' });
  const [type, setType] = useState('custom');
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();
  const unitData = useSelector((state) => state.units);
  const { units, attack, onSave, name, current, max, teams, toggle, unitType, noMax, snapshotUnits } = props;

  const unitSnapData = useCallback((team) => {

    let mapTeams = [];
    
    team.forEach(t => {
      let mapToons = [];
      t.forEach(m => {
        let snapUnit = getSnapUnit(m.unit_id ? m.unit_id : m.id, snapshotUnits);
        mapToons.push({
          ...m,
          relic_level: snapUnit && snapUnit.relic_level,
          gear_level: snapUnit && snapUnit.gear_level,
          speed: snapUnit && snapUnit.speed,
          zeta: snapUnit && snapUnit.zeta,
          omicron: snapUnit && snapUnit.omicron
        })
      });
      mapTeams.push(mapToons);
    });

    return mapTeams;
  }, [snapshotUnits]);

  useEffect(() => {
    attack && setTeams([[]]);
    let updateTeams = [];
    if (max) {
      times(max)(i =>
        teams[i] ? updateTeams.push(teams[i]) : updateTeams.push([])
      );
    }
    if (noMax && teams[0] && teams[0].length > 0) { updateTeams = [...updateTeams, []] };
    setType('custom');
    setTeams(unitSnapData(updateTeams));
    setUnitList(units);
  }, [attack, teams, max, units, noMax, unitSnapData]);

  const tabChange = (e, newValue) => {
    setTabValue(newValue);
    setType('custom')
  };

  const addToon = (toons, index, value, addToon) => {
    let updateTeams = teamLists.map((t, i) => index === i ? toons : t);
    const updateUnits = value ? unitList.filter(u => u.unit_id !== value.unit_id) : [...unitList, addToon];
    if (updateTeams.length === index + 1 && noMax) {
      updateTeams = [...updateTeams, []];
    }
    
    setTeams(unitSnapData(updateTeams));
    setUnitList(updateUnits);
  }


  const selectTeam = (team) => {
    let updateSelected;
    if (selected.includes(team)) {
      updateSelected = selected.filter(s => s !== team);
    } else {
      updateSelected = [...selected, team];
    }
    setSelected(updateSelected);
  }

  const onSaveClick = () => {
    let data = type === 'custom' ? teamLists : selected;
    onSave && onSave(data);
  }

  const handleChange = (e, t) => {
    setType(t);
    attack && t === 'recommended' && loadRecommendations(playStyle, gearLevel);
  };

  const loadRecommendations = (sort, gear) => {
    let units = [];
    teamLists[tabValue].forEach((u) => units.push({ id: u.id, gl_number: gear.val !== 'auto' ? gear.val : 'R_0' }));

    const bodyPayload = {
      type: attack ? 'defense' : 'offense',
      play_style: sort.val,
      limit: 10,
      units: units
    }
    dispatch(toggleLoading(true));
    axios.post("https://gasapi.info/api/v1/recommendation/single", bodyPayload)
      .then(response => {
        dispatch(toggleLoading(false));
        let recommended = [];
        response.data.data.forEach((u) => {
          let unitString = u.off_id.split('_');
          let counters = [];
          unitString.forEach(s =>
            s !== 'NA' && counters.push(getUnit(parseInt(s), unitType === 'fleet' ? unitData.ships : unitData.toons))
          );
          recommended.push({ team: counters, banners: u.off_banners_global, winRate: u.off_winrate_perc_global });
        });
        setRecommended(recommended);
      })
      .catch(err => {
        dispatch(toggleLoading(false));
        console.log(err.response ? err.response.data.message : err);
      });
  }

  const sortChange = (sort) => {
    loadRecommendations(sort, gearLevel);
    setPlayStyle(sort)
  }

  const gearChange = (gear) => {
    loadRecommendations(playStyle, gear);
    setGearLevel(gear);
  }

  return (
    <Box className="gas-plan-drawer">
      <Box className="gas-plan-drawer__header" bgcolor="grey.900">
        <Typography variant="h6" component="h2">{name}</Typography>
        <Typography variant="h6" component="p">{current}{max && `/${max}`}</Typography>
      </Box>
      <Box className="gas-plan-drawer__teams">
        {props.attack &&
          <Tabs
            value={tabValue}
            onChange={tabChange}
            className="gas-plan-drawer__tabs"
          >
            {teams && teams.length > 0 && teams.map((t, i) =>
              <Tab key={i} icon={<Avatar src={getUnit(t[0].id, unitType === 'fleet' ? unitData.ships : unitData.toons).image} />} />
            )}
          </Tabs>
        }

        {attack && teams && teams.length > 0 &&
          <Box mt={2}>
            <CounterFilters
              type={unitType} toonsOnly data={teams[tabValue]} noRemove noWrap
            />
          </Box>
        }
        <Box mb={2}>
          <ToggleButtonGroup
            color="secondary" value={type} exclusive
            onChange={handleChange} fullWidth
          >
            <ToggleButton value="recommended">Recommended</ToggleButton>
            {attack && <ToggleButton value="plan">Plan</ToggleButton>}
            <ToggleButton value="custom">Custom</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box className="gas-plan-drawer__results">
          {type === 'recommended' && attack &&
            <Box mb={3} display='flex'>
              <GasMenu
                buttonId='sortByBtn' menuId='sortByMenu' onChange={sortChange} buttonLabel={playStyle.label}
                items={[{ label: 'Win', val: 'win' }, { label: 'Banners', val: 'banners' }, { label: 'Balanced', val: 'balanced' }]}
                selected={playStyle} startIcon={<SortIcon />}
              />
              <Box pl={3} />
              <GasMenu
                buttonId='gearLevelBtn' menuId='gearLevelMenu' onChange={gearChange} buttonLabel={gearLevel.label}
                items={[{ label: 'Opponent', val: 'auto' }, { label: 'Relic 9', val: 'R_9' }, { label: 'Relic 7', val: 'R_7' }, { label: 'Relic 5', val: 'R_5' }, { label: 'Relic 3', val: 'R_3' }, { label: 'Relic 0', val: 'R_0' }, { label: 'Gear 12', val: 'G_12' }, { label: 'Gear 11', val: 'G_11' }, { label: 'Gear 10', val: 'G_10' }, { label: 'Gear 9', val: 'G_9' }, { label: 'Gear 8', val: 'G_8' }, { label: 'Gear 7', val: 'G_7' }]}
                selected={gearLevel} startIcon={<LocalPoliceIcon />}
              />
            </Box>
          }
          {type === 'custom' ?
            teamLists.map((t, i) =>
              <CounterFilters
                key={`custom${i}`} type={unitType} toonsOnly toonList={unitList}
                addToons={addToon} index={i} noWrap data={t} showStats
              />
            )
            :
            recommended.length > 0 && recommended.map((r, i) =>
              <CounterFilters
                key={`recommended${i}`} type={unitType} toonsOnly index={i} data={r.team} byId noAdd 
                teamSelection={selectTeam} noRemove banners={r.banners.toFixed(2)} winRate={r.winRate.toFixed(2)} noWrap
              />
            )
          }
        </Box>
      </Box>
      <Box
        className="gas-plan-drawer__footer"
        py={2} bgcolor="grey.900"
      >
        <Container maxWidth="md">
          <Stack
            direction={{ xs: 'row' }} spacing={2}
            className="gas-button-stack"
          >
            <Button
              variant="outlined" size="large"
              className="gas-button-stack__btn"
              onClick={toggle}
            >Cancel</Button>
            <Button
              variant="contained" size="large"
              className="gas-button-stack__btn"
              onClick={onSaveClick}
            >Apply</Button>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

export default PlanDrawer;