import React from 'react';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getUnitDefense } from '../../utils';

const DamageResults = (props) => {
  const { data, stats, damage } = props;
  const physDmg = stats && getUnitDefense(stats.base[8] + stats.mods[8], data.leader, data.modifiers);
  const specDmg = stats && getUnitDefense(stats.base[9] + stats.mods[9], data.leader, data.modifiers);
  const calcPhys = stats && data && (damage * (physDmg.percent/100)).toFixed(0);
  const calcSpec = stats && data && (damage * (specDmg.percent/100)).toFixed(0);
  const calcPhysChange = -((calcPhys - damage) / damage) * 100;
  const calcSpecChange = -((calcSpec - damage) / damage) * 100;

  return (
    <div className="gas-dmg-results">
      <Typography variant="h6" component="h3" gutterBottom>
        Post-Mitigation Damage
      </Typography>
      <div className="gas-dmg-results__item">
        <div className="gas-dmg-results__label">Physical Damage</div>
        <div className="gas-dmg-results__value"><Chip label={`${damage} > ${calcPhys}`} /> <Chip icon={<ArrowDownwardIcon />} label={`${calcPhysChange.toFixed(2)}%`} /></div>
      </div>
      <div className="gas-dmg-results__item">
        <div className="gas-dmg-results__label">Special Damage</div>
        <div className="gas-dmg-results__value"><Chip label={`${damage} > ${calcSpec}`} /> <Chip icon={<ArrowDownwardIcon />} label={`${calcSpecChange.toFixed(2)}%`} /></div>
      </div>
    </div>
  );
}

export default DamageResults;