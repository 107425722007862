import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import { useSelector, useDispatch } from 'react-redux';
import { resetToast } from '../../slices/toast';

const SlideTransition = (props) => <Slide {...props} direction="up" />;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GasToast = () => {
  const toast = useSelector((state) => state.toast);
  const dispatch = useDispatch();

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(resetToast());
  };

  return (
    <Snackbar open={toast.show} autoHideDuration={5000} onClose={handleClose} TransitionComponent={SlideTransition}>
      <Alert onClose={handleClose} severity={toast.severity} sx={{ width: '100%' }}>{toast.message}</Alert>
    </Snackbar>
  );
}

export default GasToast;