import React, { useState } from 'react';
import classNames from 'classnames';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Chip from '@mui/material/Chip';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BarChartIcon from '@mui/icons-material/BarChart';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import SpeedIcon from '@mui/icons-material/Speed';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';

import Toon from '../toon';
import CounterDialog from './dialog';


const useStyles = makeStyles({
  cardChip: {
    border: 'none'
  },
  buttonChip: {
    marginLeft: '6px',
    padding: '18px 0',
    borderRadius: '18px'
  },
  chipPurple: {
    color: '#ce93d8 !important',
  },
  iconPurple: {
    fill: '#ce93d8 !important'
  },
  chipGreen: {
    color: '#66bb6a !important',
  },
  iconGreen: {
    fill: '#66bb6a !important'
  },
  chipLabel: {
    padding: '0'
  },
  chipIcon: {
    margin: '0 6px !important'
  }
});

const CounterCard = (props) => {

  const [open, setOpen] = useState(false);
  const { data, type } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Card className="gas-card-counter">
      <CardContent>
        <Box className={classNames('gas-card-counter__team', type === 'fleet' && 'gas-card-counter__team--fleet')}>
          <Toon base={data.AtkLeader} type={type} />
          {data.AtkUnit1 && <Toon base={data.AtkUnit1} type={type} />}
          {data.AtkUnit2 && <Toon base={data.AtkUnit2} type={type} />}
          {data.AtkUnit3 && <Toon base={data.AtkUnit3} type={type} />}
          {data.AtkUnit4 && <Toon base={data.AtkUnit4} type={type} />}
          {data.AtkUnit5 && <Toon base={data.AtkUnit5} type={type} />}
          {data.AtkUnit6 && <Toon base={data.AtkUnit6} type={type} />}
          {data.AtkUnit7 && <Toon base={data.AtkUnit7} type={type} />}
        </Box>
        <Typography align="center" color="textSecondary" variant="h5" gutterBottom>
          vs
        </Typography>
        <Box className={classNames('gas-card-counter__team', type === 'fleet' && 'gas-card-counter__team--fleet')}>
          <Toon base={data.DefLeader} type={type} />
          {data.DefUnit1 && <Toon base={data.DefUnit1} type={type} />}
          {data.DefUnit2 && <Toon base={data.DefUnit2} type={type} />}
          {data.DefUnit3 && <Toon base={data.DefUnit3} type={type} />}
          {data.DefUnit4 && <Toon base={data.DefUnit4} type={type} />}
          {data.DefUnit5 && <Toon base={data.DefUnit5} type={type} />}
          {data.DefUnit6 && <Toon base={data.DefUnit6} type={type} />}
          {data.DefUnit7 && <Toon base={data.DefUnit7} type={type} />}
        </Box>
      </CardContent>
      <CardActions disableSpacing>
        <Box className="gas-card-counter__actions">
          <Box className="gas-card-counter__stats">
            <Tooltip title="Banners" placement="top">
              <Chip
                className={classes.cardChip} label={data.banners} icon={<BookmarkIcon />}
                color={data && data.banners < '15' ? 'error' : 'default'}
                variant="outlined"
              />
            </Tooltip>
            <Tooltip title="Difficulty" placement="top">
              <Chip
                className={classes.cardChip} label={data.difficulty}
                icon={<SpeedIcon />}
                variant="outlined"
              />
            </Tooltip>
            <Tooltip title="Video Type" placement="top">
              <Chip
                className={classNames(classes.cardChip, data && data.counter_format === 'Lab' && classes.chipPurple, data && data.counter_format === 'Guide' && classes.chipGreen)} 
                label={data.counter_format}
                icon={<VoiceChatIcon />}
                variant="outlined"
                classes={{ icon: classNames(data && data.counter_format === 'Lab' && classes.iconPurple, data && data.counter_format === 'Guide' && classes.iconGreen) }}
              />
            </Tooltip>
          </Box>
          <Box className="gas-card-counter__links">
            <Tooltip title="View Video" placement="top">
              <Chip
                icon={<YouTubeIcon />} className={classes.buttonChip}
                onClick={() => window.open(data.videoLink)}
                classes={{ label: classes.chipLabel, icon: classes.chipIcon }}
              />
            </Tooltip>
            {data.ggLink && <Tooltip title="View Meta Report" placement="top">
              <Chip
                icon={<BarChartIcon />} className={classes.buttonChip}
                onClick={() => window.open(data.ggLink)}
                classes={{ label: classes.chipLabel, icon: classes.chipIcon }}
              />
            </Tooltip>}
            {data.notes &&
              <Tooltip title="View Notes" placement="top">
                <Chip
                  icon={<SpeakerNotesIcon />} className={classes.buttonChip}
                  onClick={handleClickOpen}
                  classes={{ label: classes.chipLabel, icon: classes.chipIcon }}
                />
              </Tooltip>
            }
            {data.notes &&
              <CounterDialog
                open={open} onClose={handleClose} data={data.notes}
              />
            }
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
}

export default CounterCard;