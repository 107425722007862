import { createTheme, adaptV4Theme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme(adaptV4Theme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9'
    },
    warning: {
      main: '#ffa726'
    },
    success: {
      main: '#66bb6a'
    },
    error: {
      main: '#f44336'
    },
  },
}));

export default theme;
