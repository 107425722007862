import React from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ResourcesIntro = () => {

  return (
    <Container maxWidth="md">
      <Box mb={4}>
        <Typography align="center" variant="h4" component="h2" gutterBottom>
          Resources.
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          We have a variety of resources for you to consume, use and from which to learn. 
        </Typography>
        <Typography  align="left" variant="h6" component="p" gutterBottom>
          Roster Development is about gearing, relics, mods and prioritization and approaches to maximizing the benefit for Grand Arena.
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          Guides has a variety of, you guessed it, guides for different characters, teams, fleets and everything in beween.  
          </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          Meta is for theorycrafting, discussions, opinion pieces and a large range of content if you want to dive deeply into the GAC meta ocean.
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          Tools will provide a list of different calculators, comparison spreadsheets, bots and other helpful resources.  
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          Links has a constantly updated and relatively thorough list of links for content creators, sources of data, discord servers and other useful information.
        </Typography>
      </Box>
    </Container>
  );
}

export default ResourcesIntro;