import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import BuddyHeader from '../images/buddy-header.jpg'
import SnapshotDetails from '../../components/snapshots/details';
import { toggleLoading } from '../../slices/loading';
import { showToast } from '../../slices/toast';

const useStyles = makeStyles((theme) => ({
  altBg: {
    background: theme.palette.grey['900']
  },
  listIcon: {
    justifyContent: 'flex-end'
  },
  scrollPaper: {
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center'
    },
  }
}));

const SnapshotsPage = () => {
  const [messageText, setMessageTxt] = useState(null);
  const [plans, setPlans] = useState(null);
  const [open, setOpen] = useState(false);
  const [showDelete, setShowDelete] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const loadPlans = useCallback(() => {
    dispatch(toggleLoading(true));
    setMessageTxt(null);
    axios.get("https://gasapi.info/api/v1/roster-snapshot")
      .then(response => {
        setPlans(response.data.data);
        dispatch(toggleLoading(false));
      })
      .catch(err => {
        dispatch(toggleLoading(false));
        console.lgo(err);
      });
  }, [dispatch]);

  useEffect(() => {
    loadPlans();
  }, [loadPlans]);

  const onDelete = () => {
    dispatch(toggleLoading(true));
    setMessageTxt(null);
    axios.delete(`https://gasapi.info/api/v1/roster-snapshot/${showDelete.id}`)
      .then(response => {
        setShowDelete(null);
        dispatch(toggleLoading(false));
        loadPlans();
        dispatch(showToast({ show: true, message: 'Snapshot deleted succesfully.', severity: 'success' }));
      })
      .catch(err => {
        dispatch(toggleLoading(false));
        setShowDelete(null);
        console.lgo(err);
      });
  }

  return (
    <Box>
      <Box textAlign="center">
        <img src={BuddyHeader} alt="GAC Buddy" />
      </Box>
      <Box pt={2} pb={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h1" gutterBottom>
            Snapshots
          </Typography>
          {messageText &&
            <Box>
              <Alert variant="filled" severity="error">{messageText}</Alert>
            </Box>
          }
          <Grid container spacing={3}>

            {plans && plans.length === 0 &&
              <Grid item xs={12}>
                <Alert variant="filled" severity="info">Start by creating your first snapshot.</Alert>
              </Grid>
            }
            {plans && plans.length > 0 && plans.map((s, i) =>
              <Grid item xs={12} sm={6} key={i}>
                <Card>
                  <CardHeader
                    title={s.custom_name}
                    subheader={`Created on: ${format(new Date(s.created_at), 'MMM	dd, yyyy')}`}
                  />
                  <CardContent sx={{ paddingTop: 0, paddingBottom: '16px !important' }}>
                    <Chip label={`GP: ${s.galactic_power}`} sx={{ marginBottom: '8px', marginRight: '8px' }} />
                    <Chip label={`Units: ${s.units.length}`} sx={{ marginBottom: '8px', marginRight: '8px' }} />
                    <Chip label={`GLs: ${s.gls.length}`} sx={{ marginBottom: '8px', marginRight: '8px' }} />
                    <Chip label={`Zetas: ${s.zetas}`} sx={{ marginBottom: '8px', marginRight: '8px' }} />
                    <Chip label={`Omicrons: ${s.omicrons}`} sx={{ marginBottom: '8px', marginRight: '8px' }} />
                    <Chip label={`Relics: ${s.relic_levels}`} sx={{ marginBottom: '8px', marginRight: '8px' }} />
                    <Button
                      variant="text" size="small"
                      onClick={() => setShowDelete(s)}
                    >Delete</Button>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
          {plans && plans.length < 10 &&
            <Box pt={4} pb={2}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }} spacing={2}
                justifyContent="center" alignItems="stretch"
              >
                <Button
                  variant="contained" size="large"
                  onClick={() => setOpen(true)}
                >New Snapshot</Button>
              </Stack>
            </Box>
          }
          {plans && plans.length === 10 &&
            <Box pt={4} pb={2}>
              <Alert variant="filled" severity="info">You can have a maximum of saved 10 snapshots. To add a new one, please delete one of the older snapshots.</Alert>
            </Box>
          }

        </Container>
      </Box>

      <Box py={4} className={classes.altBg}>
        <Container maxWidth="md">
          <Typography variant="h4" component="h2" gutterBottom>
            How to use Snapshots.
          </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et diam id sem elementum aliquet.
          </Typography>
        </Container>
      </Box>

      <SnapshotDetails click={setOpen} open={open} reloadList={loadPlans} />

      <Dialog
        open={showDelete ? true : false}
        onClose={() => setShowDelete(null)}
        aria-labelledby="dialog-delete"
        aria-describedby="dialog-delete-description"
        fullWidth maxWidth='sm'
      >
        <DialogTitle id="dialog-delete">Delete Snapshot</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-delete-description">
            Are you sure want to delete '{showDelete && showDelete.custom_name}' snapshot?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDelete(null)}>Cancel</Button>
          <Button onClick={onDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

export default SnapshotsPage;