import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import BuddyHeader from '../images/buddy-header.jpg'
import PlanList from '../../components/plan/list';
import PlanDetails from '../../components/plan/details';
import { toggleLoading } from '../../slices/loading';

const useStyles = makeStyles((theme) => ({
  altBg: {
    background: theme.palette.grey['900']
  },
  listIcon: {
    justifyContent: 'flex-end'
  },
  scrollPaper: {
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center'
    },
  }
}));

const PlanPage = () => {
  const [messageText, setMessageTxt] = useState(null);
  const [plans, setPlans] = useState(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();


  const loadPlans = useCallback(() => {
    dispatch(toggleLoading(true));
    setMessageTxt(null);
    axios.get("https://gasapi.info/api/v1/plan")
      .then(response => {
        setPlans(response.data.data);
        dispatch(toggleLoading(false));
      })
      .catch(err => {
        dispatch(toggleLoading(false));
        err.response ? setMessageTxt(err.response.data.message) : console.log(err);
      });
  }, [dispatch]);

  useEffect(() => {
    loadPlans();
  }, [loadPlans]);

  return (
    <Box>
      <Box textAlign="center">
        <img src={BuddyHeader} alt="GAC Buddy" />
      </Box>
      <Box pt={2} pb={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h1" gutterBottom>
            Saved Plans
          </Typography>
          {messageText &&
            <Box>
              <Alert variant="filled" severity="error">{messageText}</Alert>
            </Box>
          }
          {plans && plans.length === 0 &&
            <Box>
              <Alert variant="filled" severity="info">Start by creating your first plan.</Alert>
            </Box>
          }
          {plans && plans.length > 0 && <PlanList plans={plans} />}
          <Box pt={4} pb={2}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }} spacing={2}
              justifyContent="center" alignItems="stretch"
            >
              <Button
                variant="contained" size="large"
                onClick={() => setOpen(true)}
              >New Plan</Button>
            </Stack>
          </Box>

        </Container>
      </Box>

      <Box py={4} className={classes.altBg}>
        <Container maxWidth="md">
          <Typography variant="h4" component="h2" gutterBottom>
            How to use Planner.
          </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            The planning tool allows you to plan ahead for your defensive and offensive teams, just like in-game.  All of your units stats (gear levels, mods, etc) are stored in the roster snapshot and whenever you take one it pulls the live data from the game servers.
          </Typography>
        </Container>
      </Box>

      <PlanDetails click={setOpen} open={open} reloadPlans={loadPlans} />

    </Box>
  );
}

export default PlanPage;