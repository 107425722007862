import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { withRouter, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import validator from 'validator';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';

import LoginImage from '../images/buddy-dual-wielder.png';
import { toggleLoading } from '../../slices/loading';

const ResetPasswordPage = (props) => {
  const [messageText, setMessageTxt] = useState(null);
  const [messageStatus, setMessageStatus] = useState('error');
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    heroImage: {
      maxHeight: 192,
      margin: '2rem auto',
      display: 'block'
    }
  }));

  const classes = useStyles();

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = form => handleLogin(form);

  const handleLogin = (form) => {
    dispatch(toggleLoading(true));
    setMessageTxt(null);
    const loginPayload = {
      password: form.password,
      confirm_password: form.confirmPassword,
      token: props.location.pathname.replace('/reset-password/','')
    }
    axios.post("https://gasapi.info/api/v1/auth/reset-password", loginPayload)
      .then(response => {
        dispatch(toggleLoading(false));
        props.history.push('/login/');
      })
      .catch(err => {
        dispatch(toggleLoading(false));
        err.response ? setMessageTxt(err.response.data.message) : console.log(err);
        setMessageStatus('error');
      });
  };

  return (
    <Container maxWidth="sm">
      <img src={LoginImage} alt="GAC Buddy" className={classes.heroImage} />
      <Typography component="h1" variant="h4" align="center">Reset Password</Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          id="password" label="Password" variant="filled" fullWidth margin="normal"
          type="password" {...register("password", { required: true })}
          error={errors.password ? true : false}
        />
        <TextField
          id="confirmPassword" label="Confirm Password" variant="filled" fullWidth
          type="password" margin="normal"
          {...register("confirmPassword", { validate: {
            equals: v => validator.equals(v, watch('password'))
          }})}
          error={errors.confirmPassword ? true : false}
        />
        {messageText &&
          <Alert variant="filled" severity={messageStatus}>{messageText}</Alert>
        }
        <Box pt={2} pb={4}>
          <Stack
            direction={{ xs: 'row' }} spacing={2}
            className="gas-button-stack"
          >
            <Button component={NavLink} to="/" fullWidth variant="outlined">Cancel</Button>
            <Button type="submit" color="primary" variant="contained" fullWidth>Submit</Button>
          </Stack>
        </Box>
      </Box>
      
    </Container>
  );
}
export default withRouter(ResetPasswordPage);