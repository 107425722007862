import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import DamageCalculator from '../components/damage';

const DamagePage = () => {

  return (
    <Box py={4}>
    <Container maxWidth="md">
      <Typography align="center" variant="h4" component="h2" gutterBottom>
        Damage Mitigation Simulator (only for relic-level characters).
      </Typography>
      <Typography align="left" variant="h6" component="p" gutterBottom>
        To begin using this tool, start by entering an ally code for the account you wish to review.  Then choose the character you wish to use as a baseline and the amount of incoming damage you wish to simulate.  Select Submit and wait for a moment.  The character you selected will be shown along with their current mods and stats.  At the bottom is the Post-Mitigation Damage, which is the amount of Physical or Special damage that will actually end up being applied to the character after it is mitigated.  You can use this tool to see what change in damage your character may take by using different mods or other defensive bonuses and modifiers.
      </Typography>
      <Typography align="left" variant="h6" component="p" gutterBottom>
        To create a simulation of this same character with different mods, leadership abilities or unique abilities that mitigate incoming damage, click on the Add Simulation bottom.  You can change any of the mods if you wish to use a set that will impact damage mitigation, as well as selecting one of the Leader Defense Bonuses or Additional Defense Modifiers.  These options are currently hard-coded and will be updated to include other options in the future as the game expands and we have more time.  Finally, choose Simulate to get the adjusted Post-Mitigation Damage numbers.
      </Typography>
      <Typography align="left" variant="h6" component="p" gutterBottom>
        You may have up to two Simulations active at any point, and you can go back to either simulation and modify the parameters of the simulation to remove or add bonuses or modifiers to see how it will impact the Post-Mitigation Damage.  You can also start over with a new character or damage amount by changing those variables.  You can look at characters from any players roster if you want to see how a friends characters are modded; all you need is their ally code.  Have fun!
      </Typography>
      <DamageCalculator />
    </Container>
    </Box>
  );
}

export default DamagePage;
