import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { toggleLoading } from '../../slices/loading';
import { zoneUpdateMap } from './utils';

const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center'
    },
  }
}));

const PlanDetails = (props) => {
  const { noSnaps, data, isEdit, click, updatePlan, history, open, snapshotData, setNoSnaps, reloadPlans } = props;
  const [style, setStyle] = useState('MAXOFFWIN');
  const [format, setFormat] = useState('FIVEVFIVE');
  const [planType, setPlanType] = useState('custom');
  const [snapshot, setSnapshot] = useState('existing');
  const [messageText, setMessageTxt] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const userSnapshots = useSelector((state) => state.snapshots);

  useEffect(() => {
    if (isEdit && data) {
      setStyle(data.play_style);
      setFormat(data.format);
    }
  }, [data, isEdit]);

  const changeStyle = (e, v) => {
    v && setStyle(v);
  };

  const changeType = (e, v) => {
    v && setPlanType(v);
    v === 'auto' && setSnapshot('existing');
  };

  const changeFormat = (e, v) => {
    v && setFormat(v);
  };

  const changeSnapshot = (e, v) => {
    v && setSnapshot(v);
  };

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = form => handleCreate(form);

  const handleCreate = (form) => {
    dispatch(toggleLoading(true));
    setMessageTxt(null);

    let updateZones = [];
    data && data.zones && data.zones.forEach((z) => {
      updateZones.push({ zone_id: z.zone_id, teams: zoneUpdateMap(z.teams) });
    });

    const bodyPayload = {
      description: form.description,
      play_style: style,
      format: format,
      roster_snapshot_id: form.selectSnapshot && form.selectSnapshot.id ? form.selectSnapshot.id : snapshotData ? snapshotData.id : null,
      roster_snapshot_name: snapshot === 'new' ? form.newSnapshot : form.selectSnapshot && form.selectSnapshot.custom_name ? form.selectSnapshot.id : snapshotData ? snapshotData.custom_name : null,
      zones: isEdit ? updateZones : []
    }
    if (isEdit) {
      axios.put(`https://gasapi.info/api/v1/plan/${data.plan_id}`, bodyPayload)
        .then(response => {
          dispatch(toggleLoading(false));
          setNoSnaps(false);
          click(false);
          updatePlan({
            ...data,
            description: form.description,
            format: format,
            roster_snapshot_id: form.selectSnapshot && form.selectSnapshot.id ? form.selectSnapshot.id : snapshotData ? snapshotData.id : null,
            roster_snapshot_name: snapshot === 'new' ? form.newSnapshot : form.selectSnapshot && form.selectSnapshot.custom_name ? form.selectSnapshot.id : snapshotData ? snapshotData.custom_name : null,
            play_style: style
          });
        })
        .catch(err => {
          dispatch(toggleLoading(false));
          err.response ? setMessageTxt(err.response.data.message) : console.log(err);
        });
    } else if(planType === 'auto') {
      axios.post("https://gasapi.info/api/v1/recommendation/roster", {
        roster_snapshot_id: form.selectSnapshot.id,
        play_style: style
      })
        .then(response => {
          dispatch(toggleLoading(false));
          click(false);
          reloadPlans();
        })
        .catch(err => {
          dispatch(toggleLoading(false));
          err.response ? setMessageTxt(err.response.data.message) : console.log(err);
        });
    } else {
      axios.post("https://gasapi.info/api/v1/plan", bodyPayload)
        .then(response => {
          dispatch(toggleLoading(false));
          history.push(`/plan/${response.data.data.plan_id}/`);
        })
        .catch(err => {
          dispatch(toggleLoading(false));
          err.response ? setMessageTxt(err.response.data.message) : console.log(err);
        });
    }
  };

  return (

    <Dialog open={open} onClose={() => click(false)} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth='sm'>
      {noSnaps ?
        <DialogTitle>Assign Snapshot</DialogTitle>
        :
        <DialogTitle>{isEdit ? 'Edit' : 'New'} Plan</DialogTitle>
      }
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          {!isEdit && !noSnaps &&
            <Box mb={planType === 'custom' ? 2 : 0}>
              <Typography variant="body1" component="p" gutterBottom>
                Play Type
              </Typography>
              <ToggleButtonGroup
                color="secondary" value={planType} exclusive
                onChange={changeType} size="small" fullWidth
              >
                <ToggleButton value="custom">Custom</ToggleButton>
                <ToggleButton value="auto">Auto</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          }

          {planType === 'custom' &&
            <TextField
              autoFocus margin="dense" id="description" defaultValue={isEdit && data.description}
              label="Plan Name" fullWidth variant="standard"
              {...register("description", { required: true })}
              error={errors.description ? true : false}
              inputProps={{ readOnly: noSnaps }}
            />
          }

          {!noSnaps &&
            <Box pt={4}>
              <Typography variant="body1" component="p" gutterBottom>
                Format
              </Typography>
              <ToggleButtonGroup
                color="secondary" value={format} exclusive
                onChange={changeFormat} size="small" fullWidth
              >
                <ToggleButton value="FIVEVFIVE">5v5</ToggleButton>
                <ToggleButton value="THREEVTHREE">3v3</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          }

          {!noSnaps &&
            <Box pt={4} mb={planType === 'auto' ? 2 : 0}>
              <Typography variant="body1" component="p" gutterBottom>
                Play Style
              </Typography>
              <ToggleButtonGroup
                color="secondary" value={style} exclusive
                onChange={changeStyle} size="small" fullWidth
              >
                <ToggleButton value="MAXOFFWIN">Max Offense</ToggleButton>
                <ToggleButton value="MAXDEFHOLD">Max Defense</ToggleButton>
                <ToggleButton value="OFFBALANCED">Balanced</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          }

          {noSnaps &&
            <Box>
              <Alert variant="filled" severity="error">There is no snapshot assigned to this plan. Before proceeding please select a snapshot that you want to assign to this plan.</Alert>
            </Box>
          }

          {planType === 'custom' &&
            <Box pt={4} mb={2}>
              <Typography variant="body1" component="p" gutterBottom>
                Snapshot
              </Typography>
              <ToggleButtonGroup
                color="secondary" value={snapshot} exclusive
                onChange={changeSnapshot} size="small" fullWidth
              >
                <ToggleButton value="existing">Existing</ToggleButton>
                <ToggleButton value="new">New</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          }

          {snapshot === 'new' ?
            <TextField
              autoFocus margin="dense" id="newSnapshot"
              label="Snapshot Name" fullWidth variant="standard"
              {...register("newSnapshot", { required: true })}
              error={errors.newSnapshot ? true : false}
            />
            : userSnapshots.length > 0 ?
              <FormControl fullWidth>
                <InputLabel variant="standard" id="selectSnapshotLabel">Select Snapshot</InputLabel>
                <Select
                  labelId="selectSnapshotLabel"
                  id="selectSnapshot"
                  defaultValue={isEdit && snapshotData ? userSnapshots.find(f => f.id === snapshotData.id) : ''}
                  label="Select Snapshot"
                  variant="standard"
                  {...register("selectSnapshot", { required: true })}
                  error={errors.selectSnapshot ? true : false}
                >
                  {userSnapshots.map((s, i) =>
                    <MenuItem key={i} value={s}>{s.custom_name}</MenuItem>
                  )}
                </Select>
              </FormControl>
              :
              <Box>
                <Alert variant="filled" severity="info">You have no snapshots created. Select 'New' above to create your first snapshot.</Alert>
              </Box>
          }



          {messageText &&
            <Box pt={4}>
              <Alert variant="filled" severity="error">{messageText}</Alert>
            </Box>
          }

          <Box pt={4}>
            <Stack
              direction={{ xs: 'row' }} spacing={2}
              justifyContent="flex-end"
              className="gas-button-stack"
            >
              {noSnaps ?
                <Button onClick={() => history.push('/plan/')}>Back</Button>
                :
                <Button onClick={() => click(false)}>Cancel</Button>
              }
              <Button type="submit" variant="contained">{isEdit ? 'Save' : 'Create'}</Button>
            </Stack>
          </Box>
        </Box>

      </DialogContent>
    </Dialog>
  );
}

export default withRouter(PlanDetails);