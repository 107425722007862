import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';


export default function ComingsoonPage() {

  return (
    <Box py={4}>  
      <Container maxWidth="md">
        <Typography align="center" variant="h4" component="h2" gutterBottom>
          Coming Soon.
        </Typography>
        <Typography  align="left" variant="h6" component="p" gutterBottom>
          The features listed below are in the process of being designed and developed.  The list does not include technical publications (guides, resources, faction overviews, etc) of the community, since the discussion and constant revision of those occur within the various groups on Discord.  You may note there are not dates included, because this is a rather small operation and things will be implemented as time is available.  All of the listed items here are actively being worked on, however.
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          -- Android and iOS applications.  As of 15th July these are completed and in the process of being tested.  
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          -- Localization into French, Spanish and Russian.
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          -- GAC Personal Assistant and Planning Tool (name TBD).  This tool will allow for Roster Snapshots, an interface in which you can plan your defense and attack and save these plans, as well as team recommendations for all of these processes.  It will also have a GAC history review and compare feature for both you and your opponents, to see highlights and challenges of your current and past matchups.
        </Typography>
      </Container>
    </Box>
  );
}
