import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import './style.scss';

const GasMenu = (props) => {
  const { buttonId, menuId, buttonLabel, items, onChange, startIcon, endIcon, color, variant, fullWidth, selected } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (item) => {
    setAnchorEl(null);
    onChange && onChange(item.val ? item : selected);
  };

  return (
    <Box className='gas-menu'>
      <Button
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={startIcon}
        endIcon={endIcon}
        color={color}
        variant={variant}
        fullWidth={fullWidth}
      >
        {buttonLabel}
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': buttonId,
        }}
      >
        {items && items.length > 0 && items.map((m, i) =>
          <MenuItem key={i} onClick={() => handleClose(m)}>{m.label}</MenuItem>
        )}
      </Menu>
    </Box>
  );
}

export default GasMenu;