import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import ImperialTroopers from './assets/Troopers_Overview_15Jun21.png';
import LinkList from '../../components/linkList';

const links = [
  {
    title: 'SWGOH.GG; all the Imperial Troopers data',
    description: 'SWGOH.GG; Imperial Troopers',
    type: 'web',
    url: 'https://swgoh.gg/characters/f/imperial-trooper/'
  },
  {
    title: 'Join discord to ask questions and talk to experts',
    description: 'Grand Arena Science discord',
    type: 'web',
    url: 'https://discord.gg/29yYq2JT57'
  },

];

export default function ImperialTroopersPage() {

  return (
    <Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Imperial Troopers.
        </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            Imperial Troopers are one of the most versatile and powerful non-Galactic Legend teams currently (as of June 2021) in the game.  This Overview is aimed at showing three variants of team that are common at different stages of the game.  They are the most effective non-GL team in taking out Sith Eternal Emperor, and are able to kill nearly all other defensive GAC teams if they are modded and played correctly.
          </Typography>
        </Container>
      </Box>

      <Box py={4} bgcolor="background.paper">
        <Container maxWidth="md">
            <img src={ImperialTroopers} alt="Imperial Troopers Diagram" />
        </Container>
      </Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Links.
          </Typography>
          <LinkList items={links} />
        </Container>
      </Box>

    </Box>
  );
}