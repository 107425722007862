import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';


export default function PrivacyPage() {

  return (
    <Box py={4}>  
      <Container maxWidth="md">
        <Typography align="center" variant="h4" component="h2" gutterBottom>
          Privacy Policy for Grand Arena Science.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          Last Updated: 9th July, 2021.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Privacy Policy Generator.
        </Typography>
        <Typography align="center" variant="h5" component="h2" gutterBottom>
          Interpretation and Definitions
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
         Interpretation
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          Definitions
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          For the purposes of this Privacy Policy:
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- Account means a unique account created for You to access our Service or parts of our Service.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to CSMR LLC.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- Country refers to: Washington, United States
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- Personal Data is any information that relates to an identified or identifiable individual.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- Service refers to the Website.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- Website refers to Grand Arena Science, accessible from https://grandarenascience.com
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
        </Typography>
        <Typography align="center" variant="h4" component="h2" gutterBottom>
          Collecting and Using Your Personal Data
        </Typography>
        <Typography align="center" variant="h5" component="h2" gutterBottom>
          Types of Data Collected
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          Personal Data
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to: Email address and Usage Data
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          Usage Data
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          Usage Data is collected automatically when using the Service.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          Tracking Technologies and Cookies
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
        </Typography>
        <Typography align="center" variant="h5" component="h2" gutterBottom>
          Use of Your Personal Data
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          The Company may use Personal Data for the following purposes:
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- To provide and maintain our Service, including to monitor the usage of our Service.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- To contact You: To contact You by email other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- To manage Your requests: To attend and manage Your requests to Us.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          We may share Your personal information in the following situations:
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          -- With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.
        </Typography>
        <Typography align="center" variant="h5" component="h2" gutterBottom>
          Retention of Your Personal Data
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
        </Typography>
        <Typography align="center" variant="h5" component="h2" gutterBottom>
          Transfer of Your Personal Data
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
        </Typography>
        <Typography align="center" variant="h5" component="h2" gutterBottom>
          Disclosure of Your Personal Data
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          Law enforcement
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
        </Typography>
        <Typography align="left" variant="h6" component="p" gutterBottom>
          Other legal requirements
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          The Company may disclose Your Personal Data in the good faith belief that such action is necessary to: Comply with a legal obligation, Protect and defend the rights or property of the Company, Prevent or investigate possible wrongdoing in connection with the Service, Protect the personal safety of Users of the Service or the public or Protect against legal liability.
        </Typography>
        <Typography align="center" variant="h5" component="h2" gutterBottom>
          Security of Your Personal Data
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
        </Typography>
        <Typography align="center" variant="h4" component="h2" gutterBottom>
          Children's Privacy
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
        </Typography>
        <Typography align="center" variant="h4" component="h2" gutterBottom>
          Links to Other Websites
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
        </Typography>
        <Typography align="center" variant="h4" component="h2" gutterBottom>
          Changes to this Privacy Policy
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
          You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
        </Typography>
        <Typography align="center" variant="h4" component="h2" gutterBottom>
          Contact Us
        </Typography>
        <Typography align="left" variant="h7" component="p" gutterBottom>
        If you have any questions about this Privacy Policy, You can contact us by visiting this page on our website: https://grandarenascience.com/contact/
        </Typography>
      </Container>
    </Box>
  );
}
