import { createSlice } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        profile: null
    },
    reducers: {
        loadProfile: (state, { payload }) => {
            return payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { loadProfile } = profileSlice.actions;

export default profileSlice.reducer;