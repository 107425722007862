import React, { useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { db } from '../firebaseConfig';

import ResourcesIntro from '../components/resources/intro';
import ResourceMenu from '../components/resources/menu';
import ResourcesGrid from '../components/resources';
import { buildCreatorList } from '../utils'

const ResourcesPage = (props) => {
  const [list, setList] = useState([]);
  const [category, setCategory] = useState('All');
  const [creator, setCreator] = useState(null);
  const [creators, setCreators] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchData = useCallback(async () => {
    setOpen(true);
    const type = props.match.params.type;
    const response = db.collection('gas_resources');
    const data = await response
      .where('resource_type', '==', type)
      .where('category', category === 'All' ? '!=' : '==', category).get();
    let buildList = [];
    data.docs.forEach(item => {
      buildList.push(item.data());
    });
    let creatorList = buildCreatorList(buildList);
    setList(buildList);
    setCreators(creatorList);
    setOpen(false);
  }, [category, props.match.params.type]);

  useEffect(() => {
    props.match.path !== '/resources/' && fetchData();
  }, [fetchData, props.match.path]);

  const selectCreator = (e, selected) => {
    let buildList = selected ? list.filter((l) => l.creator === selected) : fetchData();
    buildList.length > 0 && setList(buildList);
    selected && setCreator(selected);
  }

  const resetFilters = () => {
    setCategory('All');
    setCreator(null);
    setOpen(true);
  }

  return (
    <Box>
      {props.match.path === '/resources/' && 
        <Box pt={4}>
          <ResourcesIntro />
        </Box>
      }

      <ResourceMenu resetFilters={resetFilters} type={props.match.params.type} />

      {props.match.path !== '/resources/' && 
        <Container maxWidth="xl">
          <Box py={4}>
            <Typography 
              align="center" variant="h4" component="h2" gutterBottom
              style={{ textTransform: 'capitalize' }}
            >
              {props.match.params.type}.
            </Typography>
            <ResourcesGrid
              category={category} setCategory={setCategory}
              creator={creator} selectCreator={selectCreator}
              creators={creators} open={open} list={list}
              type={props.match.params.type} setCreator={setCreator}
            />
          </Box>
        </Container>
      }

    </Box>
  );
}

export default ResourcesPage;