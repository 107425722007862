import { createSlice } from '@reduxjs/toolkit';

export const unitsSlice = createSlice({
    name: 'units',
    initialState: {
        units: {
            toons: [],
            ships: []
        }
    },
    reducers: {
        addUnits: (state, { payload }) => {
            let toons = payload.filter(f => f.type === 1);
            let ships = payload.filter(f => f.type === 2);
            return {
                toons: toons,
                ships: ships,
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { addUnits } = unitsSlice.actions;

export default unitsSlice.reducer;