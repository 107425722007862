import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

import GacMap from './gac-map.svg';
import PlanZone from './zone';
import { toggleLoading } from '../../slices/loading';
import { showToast } from '../../slices/toast';
import { zoneUpdateMap } from './utils';
import { getUnit } from '../../utils';
import PlanList from './list';
import ButtonStack from '../buttonStack';
import './style.scss';
import PlanLoadouts from './loadouts';

const PlanMap = (props) => {
  const [plan, setPlan] = useState(null);
  const [snapshot, setSnapshot] = useState({});
  const [characters, setCharacters] = useState([]);
  const [ships, setShips] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [zoneOne, setZoneOne] = useState([]);
  const [zoneTwo, setZoneTwo] = useState([]);
  const [zoneThree, setZoneThree] = useState([]);
  const [zoneFour, setZoneFour] = useState([]);
  const [zoneFive, setZoneFive] = useState([]);
  const [zoneSix, setZoneSix] = useState([]);
  const [zoneSeven, setZoneSeven] = useState([]);
  const [zoneEight, setZoneEight] = useState([]);
  const [offenseShips, setOffenseShips] = useState([]);
  const [offenseCharacters, setOffenseCharacters] = useState([]);
  const [alignment, setAlignment] = useState('def');
  const [showDrawer, setShowDrawer] = useState(false);
  
  const planFormat = plan && plan.format === 'FIVEVFIVE' ? '5v5' : '3v3'; 
  const units = useSelector((state) => state.units);
  const zones = useSelector((state) => state.zones);
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    drawerHeader: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }));
  const classes = useStyles();

  const { attack, defense, updatePlan, planId, updateSnapshot, history } = props;


  const buildUnitList = useCallback((all, used) => {
    let filterUnits = all.filter(x => {
      return !used.flat().some(t => t.id === x.unit_id)
    });
    let allUnits = units && units.toons && [...units.toons, ...units.ships];
    let toons = [];
    let ships = [];
    filterUnits.forEach(f => {
      const unitType = getUnit(f.unit_id, allUnits);
      const buildUnit = {...f, type: unitType && unitType.type };
      unitType && unitType.type === 1 ? toons.push(buildUnit) : ships.push(buildUnit);            
    });
    setCharacters(toons);
    setShips(ships);
  }, [units]);

  const getSnapshot = useCallback((snapId, usedUnits) => {
    if (snapId) {
      dispatch(toggleLoading(true));
      axios.get(`https://gasapi.info/api/v1/roster-snapshot/${snapId}`)
        .then(response => {
          buildUnitList(response.data.data.units, usedUnits);
          setSnapshot(response.data.data);
          updateSnapshot(response.data.data);
          dispatch(toggleLoading(false));
        })
        .catch(err => {
          dispatch(toggleLoading(false));
          console.log(err.response ? err.response.data.message : err);
        });
    }
  }, [dispatch, updateSnapshot, buildUnitList]);

  const getPlan = useCallback((id) => {
    dispatch(toggleLoading(true));
    axios.get(`https://gasapi.info/api/v1/plan/${id}`)
      .then(response => {
        let usedCharacters = [];
        setPlan(response.data.data);
        updatePlan && updatePlan(response.data.data);
        response.data.data.zones.forEach(z => {
          loadZones(z.teams, z.zone_id)
          z.teams.map(t => usedCharacters.push(t))
        });
        response.data.data.characters.forEach(c => usedCharacters.push(c));
        response.data.data.ships.forEach(s => usedCharacters.push(s));
        setOffenseShips(response.data.data.ships);
        setOffenseCharacters(response.data.data.characters);
        dispatch(toggleLoading(false));
        getSnapshot(response.data.data.roster_snapshot_id, usedCharacters);
      })

      .catch(err => {
        dispatch(toggleLoading(false));
        console.log(err.response ? err.response.data.message : err);
      });
  }, [dispatch, getSnapshot, updatePlan]);

  useEffect(() => {

    if (!(attack || defense)) {
      getPlan(planId);
    }
    if (attack) {
      setAlignment('off');
      !planId && setShowDrawer(true);
    }

    if (attack || defense) {
      dispatch(toggleLoading(true));
      axios.get('https://gasapi.info/api/v1/plan')
        .then(response => {
          setPlanList(response.data.data);
          dispatch(toggleLoading(false));
        })
        .catch(err => {
          dispatch(toggleLoading(false));
          console.log(err.response ? err.response.data.message : err);
        });
    }

  }, [attack, planId, dispatch, updatePlan, defense, getSnapshot, getPlan]);

  const handleChange = (e, newAlignment) => {
    setAlignment(newAlignment);
  };

  const loadZones = (data, id) => {
    if (id === 1) { setZoneOne(data); }
    else if (id === 2) { setZoneTwo(data); }
    else if (id === 3) { setZoneThree(data); }
    else if (id === 4) { setZoneFour(data); }
    else if (id === 5) { setZoneFive(data); }
    else if (id === 6) { setZoneSix(data); }
    else if (id === 7) { setZoneSeven(data); }
    else if (id === 8) { setZoneEight(data); }
  }

  const loadOffense = (data, id) => {
    if (id === 'fleet') { setOffenseShips(data); } else { setOffenseCharacters(data); }
  }

  const onZoneUpdate = (data, id, type) => {
    removeSelectedUnits(data, type);
    loadZones(data, id);
  }

  const onOffenseUpdate = (data, id) => {
    removeSelectedUnits(data, id);
    loadOffense(data, id);
  }

  const removeSelectedUnits = (data, id) => {
    const curentUnits = id === 'fleet' ? ships : characters;
    let filterSelectedUnits = curentUnits.filter(x => {
      return !data.flat().some(t => t.unit_id === x.unit_id)
    });
    id === 'fleet' ? setShips(filterSelectedUnits) : setCharacters(filterSelectedUnits);
  }

  const onPlanSave = () => {
    dispatch(toggleLoading(true));
    let id = planId ? planId : plan && plan.plan_id;
    let updateZones = [];
    zoneOne.length > 0 && updateZones.push({ zone_id: 1, teams: zoneUpdateMap(zoneOne) });
    zoneTwo.length > 0 && updateZones.push({ zone_id: 2, teams: zoneUpdateMap(zoneTwo) });
    zoneThree.length > 0 && updateZones.push({ zone_id: 3, teams: zoneUpdateMap(zoneThree) });
    zoneFour.length > 0 && updateZones.push({ zone_id: 4, teams: zoneUpdateMap(zoneFour) });
    zoneFive.length > 0 && updateZones.push({ zone_id: 5, teams: zoneUpdateMap(zoneFive) });
    zoneSix.length > 0 && updateZones.push({ zone_id: 6, teams: zoneUpdateMap(zoneSix) });
    zoneSeven.length > 0 && updateZones.push({ zone_id: 7, teams: zoneUpdateMap(zoneSeven) });
    zoneEight.length > 0 && updateZones.push({ zone_id: 8, teams: zoneUpdateMap(zoneEight) });
    let savePlan = {
      description: plan.description,
      play_style: plan.play_style,
      format: plan.format,
      zones: updateZones,
      characters: zoneUpdateMap(offenseCharacters),
      ships: zoneUpdateMap(offenseShips),
      roster_snapshot_id: snapshot.id ? snapshot.id : null,
      roster_snapshot_name: snapshot.custom_name ? snapshot.custom_name : null,
    }
    axios.put(`https://gasapi.info/api/v1/plan/${id}`, savePlan)
      .then(response => {
        dispatch(toggleLoading(false));
        dispatch(showToast({ show: true, message: 'Plan updated succesfully.', severity: 'success' }));
      })
      .catch(err => {
        dispatch(toggleLoading(false));
        console.log(err.response ? err.response.data.message : err);
      });
  }

  const togglePlans = (id) => {    
    getPlan(id);
    setShowDrawer(!showDrawer);
  };

  const saveButtons = [
    { variant: 'outlined', size: 'large', component: NavLink, to: attack ? '/play/' : '/plan/', label: 'Cancel' },
    { variant: 'contained', size: 'large', click: onPlanSave, label: 'Save' }
  ];

  return (
    <Box>
      <Container maxWidth="md">
        <Box className="gas-plan-map" py={2}>

          <Box className="gas-plan-map__menu">

            {!attack && 
              <PlanLoadouts 
                unitType={planFormat} ships={ships} characters={characters} snapshotUnits={snapshot.units}
                teams={{ characters: offenseCharacters, ships: offenseShips }} onOffenseUpdate={onOffenseUpdate}
              />
            }

            <ToggleButtonGroup
              color="secondary"
              value={alignment}
              exclusive
              onChange={handleChange}
              size="small"
              className="gas-plan-map__phase"
            >
              <ToggleButton value="def">Defense</ToggleButton>
              <ToggleButton value="off" disabled={defense}>Offense</ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box className="gas-plan-map__zones">
            <Box className={classNames('gas-plan-map__slider', alignment === 'off' && 'gas-plan-map__slider--offense')}>
              <img
                src={GacMap} alt=""
                className="gas-plan-map__drawing"
              />
              <Box className="gas-plan-map__defense">
                <PlanZone
                  name="Fleet" type="defense-fleet" id={1} snapshotUnits={snapshot.units}
                  max={zones && plan && zones[plan.format].fleet} disabled={attack} units={ships}
                  teams={zoneOne} onZoneUpdate={onZoneUpdate} unitType="fleet"
                />
                <PlanZone
                  name="Squad" type="defense-north" id={2} snapshotUnits={snapshot.units}
                  max={zones && plan && zones[plan.format].north} disabled={attack} units={characters}
                  teams={zoneTwo} onZoneUpdate={onZoneUpdate} unitType={planFormat}
                />
                <PlanZone
                  name="Squad" type="defense-trap" id={3} snapshotUnits={snapshot.units}
                  max={zones && plan && zones[plan.format].trap} headerBottom disabled={attack} units={characters}
                  teams={zoneThree} onZoneUpdate={onZoneUpdate} unitType={planFormat}
                />
                <PlanZone
                  name="Squad" type="defense-south" id={4} snapshotUnits={snapshot.units}
                  max={zones && plan && zones[plan.format].south} headerBottom disabled={attack} units={characters}
                  teams={zoneFour} onZoneUpdate={onZoneUpdate} unitType={planFormat}
                />
              </Box>
              <Box className="gas-plan-map__offense">
                <PlanZone
                  name="Squad" type="offense-north" id={6}
                  disabled={defense} units={characters} snapshotUnits={snapshot.units}
                  teams={zoneSix} attack={attack} max={zones && plan && zones[plan.format].north}
                  onZoneUpdate={onZoneUpdate} unitType={planFormat}
                />
                <PlanZone
                  name="Fleet" type="offense-fleet" id={5}
                  disabled={defense} units={ships} snapshotUnits={snapshot.units}
                  teams={zoneFive} attack={attack} max={zones && plan && zones[plan.format].fleet}
                  onZoneUpdate={onZoneUpdate} unitType="fleet"
                />
                <PlanZone
                  name="Squad" type="offense-south" id={8} unitType={planFormat}
                  headerBottom disabled={defense} snapshotUnits={snapshot.units}
                  teams={zoneEight} attack={attack} max={zones && plan && zones[plan.format].south}
                  onZoneUpdate={onZoneUpdate} units={characters}
                />
                <PlanZone
                  name="Squad" type="offense-trap" id={7} unitType={planFormat}
                  headerBottom disabled={defense} snapshotUnits={snapshot.units}
                  teams={zoneSeven} attack={attack} max={zones && plan && zones[plan.format].trap}
                  onZoneUpdate={onZoneUpdate} units={characters}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      <ButtonStack
        buttons={saveButtons}
      />

      {(attack || defense) &&
        <Drawer
          anchor="bottom"
          open={showDrawer}
        >
          <Box px={2} py={2} className={classes.drawerHeader}>
            <Typography align="left" variant="h5" component="h2">Load Plan</Typography>
            <IconButton onClick={() => history.push('/play/')}>
              <CancelRoundedIcon />
            </IconButton>
          </Box>
          <PlanList action={togglePlans} plans={planList} />
        </Drawer>
      }
    </Box>
  );
}

export default withRouter(PlanMap);