import React from 'react';
import { NavLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'

const PlayFooter = (props) => {

  return (
    <Box className="gas-play-footer" pt={4} pb={2}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }} spacing={2}
        justifyContent="center" alignItems="stretch"
      >
        {props.contained &&
          <Button
            disabled={props.containedDisabled}
            variant="contained" size="large"
            component={NavLink} to={`/${props.contained.to}/`}
          >{props.contained.label}</Button>
        }
        {props.outlined &&
          <Button
            variant="outlined" size="large"
            component={NavLink} to={`/${props.outlined.to}/`}
          >{props.outlined.label}</Button>
        }
      </Stack>
    </Box>
  );
}

export default PlayFooter;