import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import BountyHunters from './assets/BountyHunters FULL 4June.png';
import LinkList from '../../components/linkList';


const links = [
  {
    title: 'SWGOH.GG; all the Bounty Hunter data',
    description: 'SWGOH.GG; Bounty Hunters',
    type: 'web',
    url: 'https://swgoh.gg/characters/f/bounty-hunter/'
  },
  {
    title: 'Join discord to ask questions and talk to experts',
    description: 'Grand Arena Science discord',
    type: 'web',
    url: 'https://discord.gg/29yYq2JT57'
  },
  {
    title: 'GAS and DR 5v5 Counter Review with Bounty Hunters',
    description: 'YouTube',
    type: 'video',
    url: 'https://youtu.be/SMQfQZ4IP0Q'
  },
  {
    title: 'Moff Gideon joins Bounty Hunters',
    description: 'YouTube',
    type: 'video',
    url: 'https://www.youtube.com/watch?v=eK6vqQ4rcE4'
  },
  {
    title: 'Bounty Hunters Abuse Defense Penetration',
    description: 'YouTube',
    type: 'video',
    url: 'https://www.youtube.com/watch?v=37wN5GbMU1Y'
  },
];

export default function BountyHuntersPage() {

  return (
    <Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Bounty Hunters.
        </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            Bounty Hunters are a very flexible faction, but require very specific modding and turn order to be used in different counters.  They are useful at nearly all stages of the game and are relatively easy to acquire.
          </Typography>
        </Container>
      </Box>

      <Box py={4} bgcolor="background.paper">
        <Container maxWidth="md">
          <img src={BountyHunters} alt="Bounty Hunters Diagram" />
        </Container>
      </Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Links.
          </Typography>
          <LinkList items={links} />
        </Container>
      </Box>

    </Box>
  );
}