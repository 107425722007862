import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import BuddyHero from '../images/buddy-hero.jpg';
import BuddyPlan from '../images/buddy-notepad.png';
import BuddyPlay from '../images/buddy-lightsaber-purple.png';
import BuddyReview from '../images/buddy-datapad.png';

const BuddyPage = () => {
  const profile = useSelector((state) => state.profile);

  return (
    <Box>
      <Box textAlign="center">
        <img src={BuddyHero} alt="Grand Arena Buddy" />
      </Box>
      <Box pt={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h1">
            Hello There, {profile.game_name}!
          </Typography>
          <Typography align="center" variant="h6" component="p" gutterBottom>
            Thanks for helping us test!  Please share any and all feedback on discord.  How can GA Buddy assist you today?
          </Typography>
        </Container>
      </Box>

      <Box pb={4}>
        <Container maxWidth="xl">
          <Box py={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea component={NavLink} to="/plan/" sx={{ display: 'flex' }}>
                    <Box p={2} sx={{ maxWidth: 128, flex: '0 0 128px' }}>
                      <CardMedia component="img" image={BuddyPlan} />
                    </Box>
                    <CardContent>
                      <Typography variant="h5" component="h2">Plan</Typography>
                      <Typography variant="body2">
                        Choose a roster snapshot and start planning for an upcoming Grand Arena.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea component={NavLink} to="/play/" sx={{ display: 'flex' }}>
                    <Box p={2} sx={{ maxWidth: 128, flex: '0 0 128px' }}>
                      <CardMedia component="img" image={BuddyPlay} />
                    </Box>
                    <CardContent>
                      <Typography variant="h5" component="h2">Play</Typography>
                      <Typography variant="body2">
                        What to use against your opponents defense?  Get a recommendation!
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea component={NavLink} to="/review/" sx={{ display: 'flex' }}>
                    <Box p={2} sx={{ maxWidth: 128, flex: '0 0 128px' }}>
                      <CardMedia component="img" image={BuddyReview} />
                    </Box>
                    <CardContent>
                      <Typography variant="h5" component="h2">Review</Typography>
                      <Typography variant="body2">
                        Check out your bracket and review GAC history from past matches.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

    </Box>
  );
}

export default BuddyPage;