import { configureStore } from '@reduxjs/toolkit';
import unitsSlice from '../slices/buddy';
import loadingSlice from '../slices/loading';
import toastSlice from '../slices/toast';
import profileSlice from '../slices/profile';
import snapSlice from '../slices/snapshots';
import statSlice from '../slices/stats';
import zoneSlice from '../slices/zones';
import scheduleSlice from '../slices/schedule';
import opponentSlice from '../slices/opponent';

export const store = configureStore({
  reducer: {
    units: unitsSlice,
    loading: loadingSlice,
    toast: toastSlice,
    profile: profileSlice,
    snapshots: snapSlice,
    stats: statSlice,
    zones: zoneSlice,
    schedule: scheduleSlice,
    opponent: opponentSlice
  },
})