import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { hasAuthToken } from '../../utils';
 
const RouteGuard = ({ component: Component, ...rest }) => {

   return (
       <Route {...rest}
           render={props => (
               hasAuthToken() ?
                   <Component {...props} />
                   :
                   <Redirect to={{ pathname: '/login/', state: { referrer: props.location } }}  />
           )}
       />
   );
};
 
export default RouteGuard;