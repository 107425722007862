import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ResourceCard from './card';
import ResourcesFilters from './filters';

const useStyles = makeStyles({
  backdrop: {
    zIndex: 1000,
  }
});

const ResourcesGrid = (props) => {
  const classes = useStyles();
    return (
        <Box>
            <ResourcesFilters
                category={props.category} setCategory={props.setCategory}
                creator={props.creator} selectCreator={props.selectCreator} 
                creators={props.creators} type={props.type} setCreator={props.setCreator}
            />
            <Grid container spacing={3}>
                {props.list && props.list.length > 0 && props.list.map((l, i) =>
                    <Grid
                        style={{ display: 'flex', flexDirection: 'column' }}
                        item xs={12} sm={6} md={4} lg={4} xl={3} key={i}
                    >
                        <ResourceCard
                            title={l.title}
                            description={l.description}
                            notes={l.review_notes}
                            link={l.link}
                            category={l.category}
                            creator={l.creator}
                            date={l.review_date}
                        />
                    </Grid>
                )}
                <Backdrop open={props.open} className={classes.backdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        </Box>
    );
}

export default ResourcesGrid;