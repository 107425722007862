import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import LeaderSplitsIMG from './assets/LeaderRecommendations_Summer2021.png';
import LinkList from '../../components/linkList';


const links = [
    {
      title: 'Join discord to ask questions and talk to experts',
      description: 'Grand Arena Science discord',
      type: 'web',
      url: 'https://discord.gg/29yYq2JT57'
    },
];


export default function LeaderSplitsPage() {

    return (
        <Box>
            <Box py={4}>
                <Container maxWidth="md">
                    <Typography align="center" variant="h4" component="h2" gutterBottom>
                        Grand Arena Leader Recommendations.
                    </Typography>
                    <Typography align="left" variant="h6" component="p" gutterBottom>
                        If you have ever had that moment looking at a new farming project, a lower gear faction, or just don't have the kit memorized and wanted to know where to use a team? We wanted to help. The idea here is to give players an idea of where teams can work well in grand arena. Moreover, this is a starting point and not the full picture. It is best to further your study if you really want to know what makes a team tick!  We are looking at leader abilities only, general faction or team use and trying to give players a good idea where a team can be used with success.
                    </Typography>
                    <Typography align="left" variant="h6" component="p" gutterBottom>
                        In general terms, a team can work well on offense, defense, or "flex" meaning that they can be used to success in either side of the field. To make this a useful guide we tried to pin down a definitive position for each leader on offense or defense, but some teams are just too good and deserved that flex title.
                    </Typography>
                    <Typography align="left" variant="h6" component="p" gutterBottom>
                        Some teams win on defense by stealing banners, being annoying to kill easily, or simply by stopping easy solos. Offensive teams can range from high recovery skill sets, high damage, or raw speed. Using those guidelines we added a score for every leader ability. This is intended to elaborate succinctly on the quality or general purpose of each lead. In this number scale we have 1 for an extremely defensive and 10 being an offensive powerhouse. This number indicates the mechanics of the lead, not their particular use case.
                    </Typography>
                    <Typography align="left" variant="h6" component="p" gutterBottom>
                        Why add a number? During the creation of this tool it became clear that there would be a few questions surrounding why a particular leader is used on a certain side of the field. The goal with this number is to explain a bit further about how the team works and would lead players into the concept we had for each team.
                    </Typography>
                    <Typography align="left" variant="h6" component="p" gutterBottom>
                        As an example, General Hux is positioned on defense below, but his kit is purely designed to stop teams which rely on turn meter gain and boost the offensive viability of his allies. This means it's a great way to ensure your opponents don't escape with full banners. This indicates that Hux lead will do well on defense with hard hitting allies like First Order Executioner and First Order Tie Pilot. While Hux's position would be defensive, his rating is slanted toward offense for that reason.
                    </Typography>
                </Container>
            </Box>
    
            <Box py={4} bgcolor="background.paper">
                <Container maxWidth="md">
                        <img src={LeaderSplitsIMG} alt="Leadership Recommendations" />
                </Container>
            </Box>
    
            <Box py={4}>
                <Container maxWidth="md">
                    <Typography align="center" variant="h4" component="h2" gutterBottom>
                        Links.
                    </Typography>
                    <LinkList items={links} />
                </Container>
            </Box>
  
        </Box>
    );
}

