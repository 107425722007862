import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import GLSOLO_3v3 from './assets/GL Solo 3v3.png';
import GLSOLO_5v5 from './assets/GL Solo 5v5.png';
import LinkList from '../../components/linkList';


const links = [
    {
        title: 'This is a nearly hour long video talking about a lot more detail about each of the GL solo options.  How to play, things to avoid, specific little tips and tricks that may be helpful if you are unsure about a specific counter.',
        description: 'Grand Arena Science Q&A discussing solo GLs',
        type: 'video',
        url: 'https://youtu.be/Uh88XVwOMKw'
    },
    {
      title: 'Join discord to ask questions and talk to experts',
      description: 'Grand Arena Science discord',
      type: 'web',
      url: 'https://discord.gg/29yYq2JT57'
    },
];


export default function SoloGLPage() {

    return (
        <Box>
            <Box py={4}>
                <Container maxWidth="md">
                    <Typography align="center" variant="h4" component="h2" gutterBottom>
                        Galactic Legends solo in Grand Arena.
                    </Typography>
                    <Typography align="left" variant="h6" component="p" gutterBottom>
                        The two images below (as well as the video link at the very bottom) are intended to outline different ways in which you can use your Galactic Legend solo in Grand Arena.  Depending on how you decide to do your strategy, often times the most effective use of a GL is to use them for maximum points on offense.  You may also need to clean up a GL on defense that you didn't quite manage to kill with your first team.  In both cases, the JPGs below are intended to serve as a quick reference that may help you win at GAC.  Good luck!
                    </Typography>
                </Container>
            </Box>
    
            <Box py={4} bgcolor="background.paper">
                <Container maxWidth="md">
                        <img src={GLSOLO_3v3} alt="GL's solo - 3v3" />
                </Container>
            </Box>

            <Box py={4} bgcolor="background.paper">
                <Container maxWidth="md">
                        <img src={GLSOLO_5v5} alt="GL's solo - 5v5" />
                </Container>
            </Box>
    
            <Box py={4}>
                <Container maxWidth="md">
                    <Typography align="center" variant="h4" component="h2" gutterBottom>
                        Links.
                    </Typography>
                    <LinkList items={links} />
                </Container>
            </Box>
  
        </Box>
    );
}

