import React from 'react';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import './style.scss';

const ButtonStack = (props) => {
  const { spacing, direction, buttons } = props;

  return (
    <Box py={2} bgcolor="grey.900">
      <Container maxWidth="md">
        <Stack
          direction={direction} spacing={spacing}
          className="gas-button-stack"
        >
          {buttons && buttons.length > 0 && buttons.map((b, i) =>
            <Button
              variant={b.variant} size={b.size}
              className="gas-button-stack__btn"
              component={b.component} to={b.to}
              onClick={b.click} key={i}
            >{b.label}</Button>
          )}
        </Stack>
      </Container>
    </Box>
  );
}

ButtonStack.defaultProps = {
  spacing: 2,
  direction: { xs: 'row' }
}

export default ButtonStack;