import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import CheckIcon from '@mui/icons-material/Check';

import { getUnit, getBaseUnit } from '../../utils';
import { OmicronIcon, ZetaIcon } from '../icons';
import './style.scss';

const Toon = (props) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    scrollPaper: {
      alignItems: 'flex-start',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center'
      },
    }
  });

  const classes = useStyles();
  const unitList = useSelector((state) => state.units);
  const [complete, setComplete] = useState(false);
  const toonData = getBaseUnit(props.src, props.type === 'fleet' ? unitList.ships : unitList.toons);
  const unitData = getUnit(props.unitId, props.type === 'fleet' ? unitList.ships : unitList.toons);
  const unitBase = getBaseUnit(props.base, props.type === 'fleet' ? unitList.ships : unitList.toons);

  const onAddChange = (e, v) => {
    setComplete(false);
    props.onAdd && props.onAdd(e, v);
  }

  return (
    <Box className="gas-toon-image">
      {((props.src && toonData) || (props.unitId && unitData) || (props.base && unitBase)) ?
        <Box className="gas-toon-image__container">
          <img src={props.base ? unitBase.image : props.unitId ? unitData.image : toonData.image} alt="" className={classNames('gas-toon-image__src', props.selected && 'gas-toon-image__src--selected')} />
          {props.selected &&
            <Box className="gas-toon-image__selected">
              <CheckIcon fontSize="large" color="primary" />
            </Box>
          }
          {props.remove &&
            <Box className="gas-toon-image__remove">
              <IconButton onClick={props.onRemove} style={{ padding: 0 }} size="large">
                <RemoveCircle fontSize="small" />
              </IconButton>
            </Box>
          }
          {props.showStats &&
            <Box className="gas-toon-image__stats">
              {props.gear === 13 && <Box className="gas-toon-image__relic">R{props.relic - 2}</Box>}
              {props.gear < 13 && props.gear !== 0 && <Box className="gas-toon-image__gear">G{props.gear}</Box>}
              {props.omicron ?
                <Box className="gas-toon-image__omicron">
                  <Box className="gas-toon-image__stat-icon"><OmicronIcon /></Box>
                  <Box className="gas-toon-image__stat-label">{props.omicron}</Box>
                </Box>
                :
                props.zeta !== 0 && props.zeta && <Box className="gas-toon-image__zeta">
                  <Box className="gas-toon-image__stat-icon"><ZetaIcon /></Box>
                  <Box className="gas-toon-image__stat-label">{props.zeta}</Box>
                </Box>
              }
            </Box>
          }
          {props.showStats && props.speed &&
            <Box className="gas-toon-image__speed">{props.speed}</Box>
          }
        </Box>
        : props.add &&
        <Box className="gas-toon-image__frame">
          <Box className="gas-toon-image__add">
            <IconButton onClick={() => setComplete(true)} size="large">
              <AddIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box>
      }
      {props.add && complete &&
        <Dialog onClose={() => setComplete(false)} open={complete} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth='sm'>
          <DialogTitle>
            <Box className="gas-toon-image__title">Add Unit
              <IconButton
                aria-label="close"
                className="gas-toon-image__close"
                onClick={() => setComplete(false)}
                size="large">
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <Box className="gas-toon-image__complete">
            <Autocomplete
              id="toonAdd"
              options={props.list}
              getOptionLabel={(option) => option ? option.name : ''}
              style={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} autoFocus label="Select or Search" variant="filled" color="secondary" />}
              onChange={onAddChange}
            />
          </Box>
        </Dialog>
      }
    </Box>
  );
}

export default Toon;