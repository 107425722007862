import { createSlice } from '@reduxjs/toolkit';

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState: [],
    reducers: {
        setSchedule: (state, { payload }) => {
            return payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setSchedule } = scheduleSlice.actions;

export default scheduleSlice.reducer;