import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import PlayHero from '../../components/play/hero';
import PlayCompare from '../../components/compare';
import PlayFooter from '../../components/play/footer';
import BuddyWait from '../images/buddy-gac-board.png';
import BuddyJoin from '../images/buddy-dual-wielder.png';
import BuddyDefense from '../images/buddy-shield.png';
import BuddyAttack from '../images/buddy-napoleon.png';
import BuddyReview from '../images/buddy-datapad.png';
import SelectOpponent from '../../components/play/selectOpponent';

const PlayPage = () => {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [contained, setContained] = useState(null);
  const [outlined, setOutlined] = useState(null);
  const [status, setStatus] = useState('attack');
  const [active, setActive] = useState(false);
  const gacSchedule = useSelector((state) => state.schedule);
  const opponent = useSelector((state) => state.opponent);

  useEffect(() => {
    setValues(status);
  }, [status]);

  const setValues = (value) => {
    let image;
    let title;
    let text;
    let contained;
    let outlined;
    let active;
    switch (value) {
      case 'join':
        image = BuddyJoin;
        title = 'Join Phase';
        text = 'Next GAC Season is starting soon. Make sure you join the event to receive the rewards!';
        contained = { label: 'Review History', to: 'review' };
        outlined = { label: 'Start Planning', to: 'plan' };
        active = false;
        break;
      case 'defense':
        image = BuddyDefense;
        title = 'Defense Phase';
        text = 'Your Opponent';
        contained = { label: 'Set Defense', to: 'defense' };
        outlined = { label: 'GAC Bracket', to: 'bracket' };
        active = true;
        break;
      case 'attack':
        image = BuddyAttack;
        title = 'Attack Phase';
        text = 'Your Opponent';
        contained = { label: 'Attack', to: 'attack' };
        outlined = { label: 'GAC Bracket', to: 'bracket' };
        active = true;
        break;
      case 'review':
        image = BuddyReview;
        title = 'Review Phase';
        text = 'Check the final results!';
        contained = { label: 'Review Scoreboard', to: 'scores' };
        outlined = { label: 'Review History', to: 'review' };
        active = false;
        break;
      default:
        image = BuddyWait;
        title = 'Next GAC Season starting in';
        text = 'Currently there is no GAC event running in the game. In the meantime you can review your history and start planning for the new season of GAC.';
        contained = { label: 'Review History', to: 'review' };
        outlined = { label: 'Start Planning', to: 'plan' };
        active = false;
    }
    setImage(image);
    setTitle(title);
    setText(text);
    setContained(contained);
    setOutlined(outlined);
    setActive(active);
  };

  return (
    <Box>
      <PlayHero image={image} title={title} status={status} date={'2023-03-21T22:00:00'} active={active} schedule={gacSchedule} />
      <Container maxWidth="sm">
        <Typography align="center" variant={active ? 'h4' : 'body1'} component="p" gutterBottom>{text}</Typography>
        {(status === 'defense' || status === 'attack') &&
          <Grid container style={{justifyContent: 'center'}}>
            <Grid item xs={12}>
              <SelectOpponent mb={2} pt={2} />
            </Grid>
          </Grid>
        }
        {(status === 'defense' || status === 'attack') && opponent && <PlayCompare opponent={opponent} />}
        <PlayFooter
          contained={contained} outlined={outlined}
          containedDisabled={(status === 'defense' || status === 'attack') && opponent ? false : true}
        />
      </Container>
    </Box>
  );
}

export default PlayPage;