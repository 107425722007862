import React from 'react';
import classNames from 'classnames';
import Countdown from 'react-countdown';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  timeChip: {
    height: '4rem',
    borderRadius: '4rem'
  },
  timeChipLabel: {
    fontSize: '2rem',
    fontWeight: 300,
    padding: '0 1.5rem'
  },
  timeChipActive: {
    height: '2.5rem',
    borderRadius: '2.5rem'
  },
  timeChipLabelActive: {
    fontSize: '1.25rem',
    fontWeight: 300,
    padding: '0 1.5rem'
  },
}));

const ctRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return null;
  } else {
    // Render a countdown
    return <span>{days}d {hours}h{minutes}m{seconds}s</span>;
  }
};

const noDayRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return null;
  } else {
    // Render a countdown
    return <span>{hours}h{minutes}m{seconds}s</span>;
  }
};

const PlayCountdown = (props) => {
  const classes = useStyles();

  return (
    <Box
      pb={!props.active && 4} textAlign="center"
      className={classNames('gas-play-countdown', props.active && 'gas-play-countdown--active', props.meta && 'gas-play-countdown--meta')}
    >
      {props.schedule && <Typography
        align="center" component="p"
        variant={props.meta ? 'body1' : props.active ? 'h6' : 'h4'}
        gutterBottom={!props.active}
        className={classNames('gas-play-countdown__title', props.meta && 'gas-play-countdown__title--meta')}
      >
        S{props.schedule.season}.W1.R1
      </Typography>}
      <Typography
        align="center" component="p"
        variant={props.meta ? 'body1' : props.active ? 'h6' : 'h4'}
        gutterBottom={!props.active}
        className={classNames('gas-play-countdown__title', props.meta && 'gas-play-countdown__title--meta')}
      >
        {props.title}
      </Typography>
      {props.meta ?
        <Countdown date={props.date} renderer={noDayRenderer} />
        :
        <Chip
          variant="outlined"
          label={<Countdown date={props.date} renderer={ctRenderer} />}
          classes={{
            label: props.active ? classes.timeChipLabelActive : classes.timeChipLabel,
            root: props.active ? classes.timeChipActive : classes.timeChip
          }}
        />
      }
    </Box>
  );
}

export default PlayCountdown;