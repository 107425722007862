import React, { useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { getBaseUnit, removeDuplicates, getSquad, filterCounters } from '../../utils';
import CounterCard from './card';
import Alert from '../alert';
import CounterFilters from './filters';
import './style.scss';
import { toggleLoading } from '../../slices/loading';

const Counters = (props) => {
  const [counterData, setCounterData] = useState([]);
  const [counterResults, setResults] = useState([]);
  const [leader, setLeader] = useState(null);
  const [toonList, setToonList] = useState(null);
  const [searchBy, setSearchBy] = useState('def');
  const dispatch = useDispatch();  
  const units = useSelector((state) => state.units);  
  const { type } = props;

  const loadCounters = (lead, toons) => {   
    
    if(toonList === null) {
      dispatch(toggleLoading(true));

      axios.get(`https://gasapi.info/api/v1/counter?gac_format=${type === 'fleet' ? 'ships' : type}&${searchBy}_leader=${lead}`)
     .then(response => {
        let dataMap = response.data.data.map((e) => {
          let defArray = [e.DefUnit1, e.DefUnit2, e.DefUnit3, e.DefUnit4, e.DefUnit5, e.DefUnit6];
          let atkArray = [e.AtkUnit1, e.AtkUnit2, e.AtkUnit3, e.AtkUnit4, e.AtkUnit5, e.AtkUnit6];
          let removeEmptiesDef = defArray.filter(empty => empty !== '');
          let removeEmptiesAtk = atkArray.filter(empty => empty !== '');
          let merge = { ...e, DefMerge: removeEmptiesDef, AtkMerge: removeEmptiesAtk };
          return merge;  
        });
       setResults(response.data.data);
       setCounterData(dataMap);
       setLeader(lead);
       buildToonList(response.data.data, lead);
       dispatch(toggleLoading(false));
     })
 
     .catch(err => {
       dispatch(toggleLoading(false));
       console.log(err.response ? err.response.data.message : err);
     });

    } else {
      let filterResults = [];
      counterData.forEach(e => { 
        let filter = searchBy === 'def' ? filterCounters(e.DefMerge, toons) : filterCounters(e.AtkMerge, toons);
        filter && filterResults.push(e);
      });
      setResults(filterResults);
    }
  }

  const buildToonList = (data, lead) => {  
    let getSquads = getSquad(data, lead, searchBy);
    let getSquadData = getSquads.map(l => getBaseUnit(l, type === 'fleet' ? units.ships : units.toons));
    setToonList(removeDuplicates(getSquadData));    
  }

  const chooseToon = (v) => {    
    let getLeader = v.length > 0 ? v[0] : null;    
    let getToon = v.filter((d, i) => i !== 0);
    let flatToons = getToon.map((t) => t.base_id);

    if(getLeader) {
      loadCounters(getLeader.base_id, flatToons);
    } else {
      setResults([]);
      setLeader(null);
    }
  }

  const changeSearchBy = (v) => {
    setSearchBy(v);
    if(v !== searchBy) {
      setResults([]);
      setLeader(null);
      setToonList(null);
    }
  }

  return (
    <Box>
      <CounterFilters type={type} addToons={chooseToon} leaders={toonList ? toonList : type === 'fleet' ? units.ships : units.toons} searchBy={searchBy} changeSearchby={changeSearchBy} />
      <Grid container spacing={3}>
        {leader && counterResults.length > 0 ? counterResults.map((c, i) =>
          <Grid item xs={12} sm={type === 'fleet' ? 10 : 6} md={type === 'fleet' ? 6 : 4} lg={4} xl={3} key={i}>
            <CounterCard type={type} data={c} />
          </Grid>
        )
        :
          leader && <Grid item>
            <Alert severity="info">No counters found for the selected team. Do you have one? Why not submit it via our Discord server.</Alert>
          </Grid>
        }
      </Grid>
    </Box>
  );
}

export default Counters;