import { createSlice } from '@reduxjs/toolkit';

export const zoneSlice = createSlice({
    name: 'zones',
    initialState: null,
    reducers: {
        addZones: (state, { payload }) => {
            return payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { addZones } = zoneSlice.actions;

export default zoneSlice.reducer;