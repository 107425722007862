import React from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';

import PlayHeader from './header';
import PlayCountdown from './countdown';
import './style.scss';

const PlayHero = (props) => {

  const useStyles = makeStyles((theme) => ({
    altBg: {
      background: props.active && theme.palette.grey['900']
    }
  }));

  const classes = useStyles();

  return (
    <Box className="gas-play-page">
      <Container maxWidth="md" className={classes.altBg}>
        <Box 
          className={classNames('gas-play-hero', props.active && 'gas-play-hero--active')} 
          py={props.active && 2} 
          mb={props.active && 2} 
        >
          <PlayHeader image={props.image} status={props.status} active={props.active} />
          <PlayCountdown date={props.date} title={props.title} status={props.status} schedule={props.schedule} active={props.active} />
        </Box>
      </Container>
    </Box>
  );
}

export default PlayHero;