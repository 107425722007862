export const zoneUpdateMap = (data) => {
  let mapData = [];
  let filterData = data.filter((f) => f.length > 0);
  filterData.forEach((m, i) => {
    let eachData = [];
    m.forEach((e, i) => {
      eachData.push({ unit_id: e.unit_id ? e.unit_id : e.id, placement: i + 1 });
    });
    mapData.push({ units: eachData });
  });
  return mapData;
}