export const times = n => f => {
  let iter = i => {
    if (i === n) return
    f(i)
    iter(i + 1)
  }
  return iter(0)
}

export const convertGp = v => {
  // Nine Zeroes for Billions
  return Math.abs(Number(v)) >= 1.0e+9

    ? Math.abs(Number(v)) / 1.0e+9 + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(v)) >= 1.0e+6

      ? (Math.abs(Number(v)) / 1.0e+6).toFixed(2) + "M"
      // Three Zeroes for Thousands
      : Math.abs(Number(v)) >= 1.0e+3

        ? Math.abs(Number(v)) / 1.0e+3 + "K"

        : Math.abs(Number(v));
}