import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px'
  },
  dialogTitle: {
    paddingRight: '96px'
  }
});

const CounterDialog = (props) => {  
  const { onClose, selectedValue, open, data } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const classes = useStyles();

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth='sm'>
      <DialogTitle className={classes.dialogTitle} onClose={handleClose}>Counter Notes 
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{data}</Typography>
      </DialogContent>
    </Dialog>
  );
}

export default CounterDialog;