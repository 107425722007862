import React from 'react';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  altBg: {
    background: theme.palette.grey['900']
  },
  listIcon: {
    justifyContent: 'flex-end'
  }
}));

const PlanList = (props) => {
  const classes = useStyles();

  return (
    <List className={classes.altBg}>
      {props.plans.map((p, i) =>
        <ListItem disablePadding key={i}>
          <ListItemButton component={!props.action ? NavLink : 'button'} to={!props.action && `/plan/${p.plan_id}/`} onClick={() => props.action && props.action(p.plan_id)}>
            <ListItemText primary={p.description} secondary={`Last updated: ${format(new Date(p.updated_at), 'MMM	dd, yyyy')}`} />
            <ListItemIcon className={classes.listIcon}>
              <ChevronRightIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      )}
    </List>
  );
}

export default PlanList;