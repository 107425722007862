import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Hero from '../components/hero';

import { convertCsvToJson } from '../utils';
import './styles/home.scss';
import BuddyPlan from './images/buddy-notepad.png'
import BuddyPlay from './images/buddy-lightsaber-purple.png'
import { toggleLoading } from '../slices/loading';

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#a6d4fa'
  },
  resLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  backdrop: {
    zIndex: 1000,
  },
  altBg: {
    background: theme.palette.grey['900']
  }
}));

const HomePage = () => {
  const [newsData, setNews] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(toggleLoading(true));
      const { data } = await axios.get('https://docs.google.com/spreadsheets/d/e/2PACX-1vTrovsrbEs4mJiln9YiW4AnSlBSmV1VQk9HgTFyDmxIGVjrT7pKG_OPHcYwzVkRyN1zc7UgqMluxXTO/pub?gid=0&single=true&output=csv');
      let convertData = convertCsvToJson(data, ',');
      setNews(convertData);
      dispatch(toggleLoading(false));
    };

    fetchData();
  }, [dispatch]);

  const classes = useStyles();

  return (
    <Box>
      <Hero home />
      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h1">
            Welcome to Grand Arena Science!
          </Typography>
          <Box pt={4} pb={2}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }} spacing={2}
              justifyContent="center" alignItems="stretch"
            >
              <Button
                variant="contained" size="large"
                component={NavLink} to="/buddy/"
              >Grand Arena Buddy</Button>
              <Button
                variant="outlined" size="large"
                component={NavLink} to="/counters/"
              >Explore Counters</Button>
            </Stack>
          </Box>
        </Container>
      </Box>

      <Box py={4} className={classes.altBg}>
        <Container maxWidth="md">
          <Box className="news">
            {newsData.length > 0 && newsData.map((n, i) =>
              <Box className="news__item" key={i} onClick={() => window.open(n.Link)}>
                <Box className="news-date">
                  <Box className="news-date__icon">
                    <Box className="news-date__date">
                      <Typography className="news-date__day">{n.Day}</Typography>
                      <Typography className="news-date__month">{n.Month}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="news__content">{n.Content}</Box>
                <Box className="news__link">
                  <SvgIcon>
                    <ArrowForwardIosIcon />
                  </SvgIcon>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography variant="h4" component="h2" gutterBottom>
            About.
          </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            This is the home of Grand Arena Science (GAS).  We're a community and resource center for the Grand Arena game mode within Star Wars: Galaxy of Heroes.  Our goal is to provide detailed and thoroughly researched data, guides and SCIENCE which will help you succeed, excel and even dominate in Grand Arena. If you have any feedback, questions or would like to make a suggestion, please join us on <Link className={classes.link} rel="noopener" href="https://discord.gg/29yYq2JT57" target="_blank">Discord</Link>.  We also occasionally add content on our <Link className={classes.link} color="secondary" rel="noopener" href="https://www.youtube.com/channel/UCcw57dMcM6PBE6XNdYZTxOg" target="_blank">YouTube</Link> channel if you would like to take a look.  Thanks for visiting!
          </Typography>
        </Container>
      </Box>

      <Box py={4} className={classes.altBg}>
        <Container maxWidth="md">
          <Typography variant="h4" component="h2" gutterBottom>
            Grand Arena Buddy.
          </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            GA Buddy is here to save you time.  Plan your defensive and offensive teams ahead of time, compare yourself to opponents and get recommendations on the best teams to use for both offense and defense.
          </Typography>
        </Container>
        <Container maxWidth="xl">
          <Box py={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea component={NavLink} to="/plan/">
                    <CardMedia component="img" image={BuddyPlan} sx={{ maxWidth: 256, margin: '0 auto' }} />
                    <CardContent>
                      <Typography align="center" variant="h5" component="h2">Plan</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea component={NavLink} to="/play/">
                    <CardMedia component="img" image={BuddyPlay} sx={{ maxWidth: 256, margin: '0 auto' }} />
                    <CardContent>
                      <Typography align="center" variant="h5" component="h2">Play</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea component={NavLink} to="/review/">
                    <CardMedia component="img" image={BuddyPlan} sx={{ maxWidth: 256, margin: '0 auto' }} />
                    <CardContent>
                      <Typography align="center" variant="h5" component="h2">Review</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography variant="h4" component="h2" gutterBottom>
            Counters.
          </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            The searchable database of Grand Arena counters, all individually curated by GAC experts.  The vast majority are from actual GAC matches, though some are guides to beat a specific team.  Every video has been individually selected as a good representation of the match (either winning or losing), and each result also has a link to the swgoh.gg counters analysis for that season.  New counters are added multiple times per week.  Start by selecting the format, selecting defense OR offense sorting and then entering the character(s) on the team you wish to use or counter by clicking on the +.
          </Typography>
        </Container>
        <Container maxWidth="xl">
          <Box py={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea component={NavLink} to="/counters/">
                    <CardMedia component="img" image="/images/5v5-card.jpg" />
                    <CardContent>
                      <Typography align="center" variant="h5" component="h2">5v5</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea component={NavLink} to="/counters/3v3/">
                    <CardMedia component="img" image="/images/3v3-card.jpg" />
                    <CardContent>
                      <Typography align="center" variant="h5" component="h2">3v3</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea component={NavLink} to="/counters/fleet/">
                    <CardMedia component="img" image="/images/fleet-card.jpg" />
                    <CardContent>
                      <Typography align="center" variant="h5" component="h2">Ships</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

    </Box>
  );
}

export default HomePage;