import React from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import './style.scss';

const Hero = (props) => {

  return (
    <Box className={classNames('gas-hero', props.short && 'gas-hero--short', props.home && 'gas-hero--home')} />
  );
}

export default Hero;