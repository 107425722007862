import React from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NumbersIcon from '@mui/icons-material/Numbers';
import TimerIcon from '@mui/icons-material/Timer';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';

import Box from '@mui/material/Box';

import Toon from '../toon';
import './style.scss';

const useStyles = makeStyles({
  cardChip: {
    border: 'none'
  }
});

const HistoryTeam = (props) => {
  const { data } = props;
  const classes = useStyles();

  return (
    <Card className="gas-history-team">
      <CardContent>
        <Box className="gas-history-team__meta">
          <Box className="gas-history-team__status">
            <Chip label={data.status} color={data.status === 'WIN' ? 'success' : 'error'} />
          </Box>
          <Box className="gas-history-team__end">
            <Tooltip title="Battle ended on" placement="top">
              <Chip
                className={classes.cardChip} label={format(new Date(data.attack_endtime), "MMM do yyyy' at 'HH:mm:ss")}
                icon={<AccessTimeIcon />}
                variant="outlined"
              />
            </Tooltip>
          </Box>
          <Box className="gas-history-team__attempts">
            <Tooltip title="Attempt" placement="top">
              <Chip
                className={classes.cardChip} label={data.attempt}
                icon={<NumbersIcon />}
                variant="outlined"
              />
            </Tooltip>
          </Box>
          <Box className="gas-history-team__banners">
            <Tooltip title="Banners" placement="top">
              <Chip
                className={classes.cardChip} label={data.banners} icon={<BookmarkIcon />}
                color={data && data.banners < '15' ? 'error' : 'default'}
                variant="outlined"
              />
            </Tooltip>
          </Box>
          <Box className="gas-history-team__length">
            <Tooltip title="Battle duration" placement="top">
              <Chip
                className={classes.cardChip} label={new Date(data.length * 1000).toISOString().substring(14, 19)}
                icon={<TimerIcon />}
                variant="outlined"
              />
            </Tooltip>
          </Box>
        </Box>

        <Box className="gas-history-team__battle">
          <Box className={classNames('gas-history-team__toons gas-history-team__toons--attack', data.type === 'SHIP' ? 'gas-history-team__toons--fleet' : 'gas-history-team__toons-character')}>
            {data.gac_units.map((u, i) => u.type === 'ATTACK' &&
              <Toon unitId={u.unit_id} key={i} type={data.type === 'SHIP' ? 'fleet' : 'character'} />
            )}
          </Box>
          <Box className="gas-history-team__vs">
            <Divider>
              <Chip label="VS" />
            </Divider>
          </Box>
          <Box className={classNames('gas-history-team__toons gas-history-team__toons--defense', data.type === 'SHIP' ? 'gas-history-team__toons--fleet' : 'gas-history-team__toons-character')}>
            {data.gac_units.map((u, i) => u.type === 'DEFENSE' &&
              <Toon unitId={u.unit_id} key={i} type={data.type === 'SHIP' ? 'fleet' : 'character'} />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default HistoryTeam;