import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Scouting from './assets/Scouting_FINAL_2Apr.png';
import LinkList from '../../components/linkList';


const links = [
  {
    title: 'Before Each Fight; a Grand Arena Science checklist of making sure each fight is successful',
    description: 'Before Each Fight',
    type: 'web',
    url: 'https://www.grandarenascience.com/resources/before-each-fight/'
  },
  {
    title: 'Join discord to ask questions and talk to experts',
    description: 'Grand Arena Science discord',
    type: 'web',
    url: 'https://discord.gg/29yYq2JT57'
  },
  {
    title: 'GAC History Search; a very short video on how to do it',
    description: 'GAC History Search from Grand Arena Science',
    type: 'video',
    url: 'https://youtu.be/1jtaveNHaBw'
  },
  {
    title: 'In-depth how to scout and plan before placing defense',
    description: 'From Tilo Mad92',
    type: 'video',
    url: 'https://youtu.be/ao5X4MCsr_I'
  },
  {
    title: 'GAC 101: Making a plan; the first in a series of GAC instructional videos, three focused entirely on scouting.',
    description: 'Excellent series from Calvin Awesome',
    type: 'video',
    url: 'https://youtu.be/Nfi8wZ5tPWk'
  },
  {
    title: 'Grand Arena Scouting: Winning The Battle Before It Begins',
    description: 'From Bulldog1205',
    type: 'video',
    url: 'https://youtu.be/VUww-z00C6s'
  },
  {
    title: 'Leveraging Risk for Advantage in GAC.',
    description: 'By Xaereth',
    type: 'video',
    url: 'https://youtu.be/8x_4I7nN52g'
  },
];

export default function ScoutingPage() {

  return (
    <Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Scouting.
        </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            Being able to scout your opponents effectively is one of the most important factors in being successful at Grand Arena.  This Overview provides a starting point for you to think about how to improve your skills and performance, and below the graphic are a number of links to more detailed guides and resources by other GAC experts.
          </Typography>
        </Container>
      </Box>

      <Box py={4} bgcolor="background.paper">
        <Container maxWidth="md">
            <img src={Scouting} alt="Scouting Overview" />
        </Container>
      </Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Links.
          </Typography>
          <LinkList items={links} />
        </Container>
      </Box>

    </Box>
  );
}