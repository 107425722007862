import React from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import Toon from '../toon';

const PlanTeams = (props) => {

  return (
    <Box 
      className={classNames('gas-plan-teams', `gas-plan-teams--${props.type}`, props.teams && props.teams.length === 0 && 'gas-plan-teams--empty')}
    >
      {props.teams && props.teams.length > 0 && props.teams.map((t, b) =>
        t.length > 0 ? t.map((c, i) => i === 0 && <Toon key={i} src={c.base_id} unitId={c.unit_id} type={props.unitType} />) :
          <Box className="gas-toon-image__frame" key={b}>
            <Box className="gas-toon-image__add">
              <IconButton size="large">
                <AddIcon fontSize="large" />
              </IconButton>
            </Box>
          </Box>
      )}
      {!props.disabled && props.teams && props.teams.length === 0 &&
        <Box className="gas-plan-teams__edit">
          <Box className="gas-toon-image__frame">
            <Box className="gas-toon-image__add">
              <IconButton size="large">
                <AddIcon fontSize="large" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      }
    </Box>
  );
}

export default PlanTeams;