import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person'
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import RestoreIcon from '@mui/icons-material/Restore';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

import { hasAuthToken } from '../../utils';
import { addUnits } from '../../slices/buddy';
import { loadProfile } from '../../slices/profile';
import { addSnapshots } from '../../slices/snapshots';
import { addZones } from '../../slices/zones';
import { toggleLoading } from '../../slices/loading';
import { setStats } from '../../slices/stats';
import { setSchedule} from '../../slices/schedule';
import './style.scss';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  menuLinks: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },
  profileButton: {
    marginLeft: theme.spacing(2),
    marginRight: '-12px'
  },
  appBar: {
    background: 'rgba(0,0,0,.8)'
  },
  drawer: {
    width: 270
  },
  brand: {
    flexGrow: 1
  }
}));

const links = [
  { label: 'Home', to: '/' },
  { label: 'Plan', to: '/plan/' },
  { label: 'Play', to: '/play/' },
  { label: 'Review', to: '/review/' },
  { label: 'Counters', to: '/counters/' },
  { label: 'Contact Us', to: '/contact/' }
];

const profileLinks = [
  { label: 'Stats', to: '/stats/', icon: <StackedBarChartIcon /> },
  { label: 'Notifications', to: '/notifications/', icon: <NotificationsIcon /> },
  { label: 'Snapshots', to: '/snapshots/', icon: <RestoreIcon /> },
  { label: 'Integrations', to: '/integrations/', icon: <IntegrationInstructionsIcon /> }
];

const Navigation = (props) => {
  const [drawer, setDrawer] = useState(false);
  const [profile, setProfile] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const userProfile = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(toggleLoading(true));
    axios.get("https://gasapi.info/api/v1/gac-schedule/season-info")
      .then(response => {
        dispatch(setSchedule(response.data.data));
      })
      .catch(err => {
        dispatch(toggleLoading(false));
        console.log(err.response ? err.response.data.message : err);
      });
      axios.get("https://gasapi.info/api/v1/unit")
        .then(response => {
          dispatch(addUnits(response.data.data));
          dispatch(toggleLoading(false));
        })
        .catch(err => {
          dispatch(toggleLoading(false));
          console.log(err.response ? err.response.data.message : err);
        });
    if (hasAuthToken()) {

      axios.get("https://gasapi.info/api/v1/auth/whoiam")
        .then(response => {
          dispatch(loadProfile(response.data.data));

          axios.get("https://gasapi.info/api/v1/roster-snapshot")
            .then(response => {
              let snaplist = response.data.data.map((s) => { return { id: s.id, custom_name: s.custom_name } });
              dispatch(addSnapshots(snaplist));
            })
            .catch(err => {
              dispatch(toggleLoading(false));
              console.log(err.response ? err.response.data.message : err);
            });

        })
        .catch(err => {
          dispatch(toggleLoading(false));
          console.log(err.response ? err.response.data.message : err);
        });

      axios.get("https://gasapi.info/api/v1/gac/stats")
        .then(response => {
          dispatch(setStats(response.data.data));
        })
        .catch(err => {
          dispatch(toggleLoading(false));
          console.log(err.response ? err.response.data.message : err);
        });

      axios.get("https://gasapi.info/api/v1/zone/plan")
        .then(response => {
          let zonePlanFive = response.data.data.find(z => z.format === '5v5');
          let zonePlanThree = response.data.data.find(z => z.format === '3v3');
          let zonePlan = {
            FIVEVFIVE: {
              format: '5v5',
              fleet: zonePlanFive.teamInfo[0].fleet.teams_per_squad[0].total_teams,
              north: zonePlanFive.teamInfo[0].squad.teams_per_squad[0].total_teams,
              south: zonePlanFive.teamInfo[0].squad.teams_per_squad[2].total_teams,
              trap: zonePlanFive.teamInfo[0].squad.teams_per_squad[1].total_teams
            },
            THREEVTHREE: {
              format: '3v3',
              fleet: zonePlanThree.teamInfo[0].fleet.teams_per_squad[0].total_teams,
              north: zonePlanThree.teamInfo[0].squad.teams_per_squad[0].total_teams,
              south: zonePlanThree.teamInfo[0].squad.teams_per_squad[2].total_teams,
              trap: zonePlanThree.teamInfo[0].squad.teams_per_squad[1].total_teams
            }
          };
          dispatch(addZones(zonePlan));
          dispatch(toggleLoading(false));
        })
        .catch(err => {
          dispatch(toggleLoading(false));
          console.log(err.response ? err.response.data.message : err);
        });

    }
    setProfile(false);
  }, [dispatch, location]);

  const Logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    setProfile(false);
    props.history.push('/');
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(open);
  };

  const toggleProfile = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setProfile(open);
  };

  const profileClick = () => {
    if (hasAuthToken()) {
      setProfile(true);
    } else {
      props.history.push('/login/');
    }
  };

  const MobileMenu = () =>
    <div
      className={classes.drawer}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box className="gas-hero gas-hero--short" />
      <List>
        {links.map((l, i) =>
          <NavLink to={l.to} key={i} className="gas-navigation__link">
            <ListItem button >
              <ListItemText primary={l.label} />
            </ListItem>
          </NavLink>
        )}
      </List>
    </div>;

  const ProfileMenu = () =>
    <div
      className={classes.drawer}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {userProfile &&
        <Box className="gas-profile">
          <AccountCircleIcon sx={{ fontSize: 96 }} />
          <Box py={1}>
            <Typography align="center" variant="subtitle2" component="h2">{userProfile.game_name}</Typography>
            <Typography align="center" variant="caption" component="h2">{userProfile.ally_code}</Typography>
          </Box>
          <Button variant="outlined" onClick={Logout}>Logout</Button>
        </Box>
      }
      <List>
        {userProfile &&
          <ListItem button onClick={() => window.open(`https://swgoh.gg/p/${userProfile.ally_code}/`, '_blank')}>
            <ListItemIcon>
              <AdminPanelSettings />
            </ListItemIcon>
            <ListItemText primary='GG Profile' />
          </ListItem>
        }
        {profileLinks.map((l, i) =>
          <NavLink to={l.to} key={i} className="gas-navigation__link">
            <ListItem button >
              <ListItemIcon>
                {l.icon}
              </ListItemIcon>
              <ListItemText primary={l.label} />
            </ListItem>
          </NavLink>
        )}
      </List>
    </div>;

  return (
    <Box className="gas-navigation">
      <AppBar position="static" className={classes.appBar} color="transparent">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.brand}>
            Grand Arena Science
          </Typography>
          <Drawer open={drawer} onClose={toggleDrawer(false)}>
            <MobileMenu />
          </Drawer>
          <Box className={classes.menuLinks}>
            {links.map((l, i) =>
              <Button
                key={i} component={NavLink}
                to={l.to} color="inherit"
              >
                {l.label}
              </Button>
            )}
          </Box>
          <IconButton
            edge="start"
            className={classes.profileButton}
            color="inherit"
            aria-label="menu"
            onClick={profileClick}
            size="large">
            <PersonIcon />
          </IconButton>
          <Drawer open={profile} onClose={toggleProfile(false)} anchor="right">
            <ProfileMenu />
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default withRouter(Navigation);