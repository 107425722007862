import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import RocketIcon from '@mui/icons-material/Rocket';
import PersonIcon from '@mui/icons-material/Person';
import PlanDrawer from './drawer';
import './style.scss';

const PlanLoadouts = (props) => {
  const [showLoadouts, setShowLoadouts] = useState(false);
  const [loadoutData, setLoadoutData] = useState({});
  const [teamList, setTeams] = useState([[]]);
  const { characters, ships, teams, unitType, onOffenseUpdate, snapshotUnits } = props;

  const toggleLoadouts = (type) => {
    let loadout = {
      unitType: type === 'ships' ? 'fleet' : unitType,
      units: type === 'ships' ? ships : characters,
      name: type === 'ships' ? 'Offense Fleets' : 'Offense Squads'
    };
    let teamArray = type === 'ships' ? teams.ships : teams.characters;
    setTeams(teamArray.length > 0 ? teamArray : [[]]);
    setLoadoutData(loadout);
    setShowLoadouts(!showLoadouts);
  };

  const onSave = (data) => {
    let updateData = [];
    data.forEach((d) => d.length > 0 && updateData.push(d));
    setTeams(updateData);
    onOffenseUpdate(updateData, loadoutData.unitType);
    setShowLoadouts(!showLoadouts);
  }

  return (
    <Box className="gas-plan-map__loadouts">

      <Box className="gas-plan-map__squads">
        <Button 
          variant="outlined" color="secondary" startIcon={<PersonIcon />} fullWidth
          onClick={() => toggleLoadouts('characters')}
        >
          Offense Squads
        </Button>
        <Button 
          variant="outlined" color="secondary" startIcon={<RocketIcon />} fullWidth
          onClick={() => toggleLoadouts('ships')}
        >
          Offense Fleets
        </Button>
      </Box>

      <Drawer
        anchor="bottom"
        open={showLoadouts}
        onClose={toggleLoadouts}
      >
        <PlanDrawer
          name={loadoutData.name} toggle={toggleLoadouts} onSave={onSave} units={loadoutData.units} noMax
          unitType={loadoutData.unitType} current={teamList.length} teams={teamList} max={teamList.length}
          snapshotUnits={snapshotUnits}
        />
      </Drawer>
      
    </Box>
  );
}

export default PlanLoadouts;