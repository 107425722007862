import { createSlice } from '@reduxjs/toolkit';

export const opponentSlice = createSlice({
    name: 'opponent',
    initialState: null,
    reducers: {
        setOpponent: (state, { payload }) => {
            return payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setOpponent } = opponentSlice.actions;

export default opponentSlice.reducer;