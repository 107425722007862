import React from 'react';
import { withRouter } from "react-router";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Counters from '../components/counters';

const CountersPage = (props) => {

  return (
    <Box>
      <Container maxWidth="xl">
        <Box my={4}>
          <Typography align="center" variant="h4" component="h1" gutterBottom>
            Counters.
          </Typography>
          <Typography align="left" variant="h6" component="h2" gutterBottom>
            The searchable database of Grand Arena counters, all individually curated by GAC experts.  The vast majority are from actual GAC matches, though some are guides to beat a specific team.  Every video has been individually selected as a good representation of the match (either winning or losing), and each result also has a link to the swgoh.gg counters analysis for that season.  New counters are added multiple times per week.  Start by selecting the format, selecting defense OR offense sorting and then entering the character(s) on the team you wish to use or counter by clicking on the +.
          </Typography>
          <Counters key={props.location.pathname} type={props.type} />
        </Box>
      </Container>
    </Box>
  );
}

export default withRouter(CountersPage);