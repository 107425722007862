import React, { useState } from 'react';

import Box from '@mui/material/Box';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  alert: {
    marginBottom: 24,
  }
});

const DamageFilters = (props) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [ally, setAlly] = useState('');
  const [unit, setUnit] = useState(null);
  const [damage, setDamage] = useState('');

  const submitFilters = () => {
    let data = {
      ally: ally.replace(/[^0-9.]/g, ''),
      unit: unit,
      damage: damage
    }
    if(ally !== '' && unit !== null && damage !== '') {
      props.onSubmit && props.onSubmit(data);
      setError(false)
    } else {
      setError(true)
    }
  }

  return (
    <Box pt={3}>
      <Grid container spacing={3} style={{ alignItems: 'center' }}>
        <Grid item xs={12} md={3}>
          <Box pb={3}>
            <TextField
              id="ally" label="Ally Code" variant="filled"
              style={{ width: '100%' }} name="ally"
              onChange={(e) => setAlly(e.target.value)} value={ally}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box pb={3}>
            <Autocomplete
              id="character"
              options={props.units}
              getOptionLabel={(option) => option.nameKey}
              style={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Select Unit" variant="filled" color="secondary" />} 
              value={unit} onChange={(e, v) => setUnit(v)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box pb={3}>
            <TextField
              id="damage" label="Damage # to simulate" variant="filled"
              style={{ width: '100%' }} name="damage"
              onChange={(e) => setDamage(e.target.value)} value={damage}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button 
            variant="contained" color="primary" size="large" 
            endIcon={<ArrowForwardIcon />} style={{ marginBottom: 24 }}
            onClick={submitFilters}
          >Submit</Button>
        </Grid>
      </Grid>
      {error &&
        <Alert className={classes.alert} severity="error">Please fill in all the fields!</Alert>
      }
    </Box>
  );
}

export default DamageFilters;