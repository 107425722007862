import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import BuddyHeader from '../images/buddy-header.jpg'
import { toggleLoading } from '../../slices/loading';
import PlayCompare from '../../components/compare';
import CompareBracket from '../../components/compare/bracket';
import SelectOpponent from '../../components/play/selectOpponent';

const BracketPage = () => {
  const [bracketData, setBracketData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const opponent = useSelector((state) => state.opponent);
  const userStats = useSelector((state) => state.stats);

  useEffect(() => {
    const loadBracket = () => {
      dispatch(toggleLoading(true));
      userStats.length > 0 && axios.get('https://gasapi.info/api/v1/gac/get-current-gac-bracket-info')
        .then(response => {
          let statData = [];
          const playerData = userStats[userStats.length - 1];
          statData.push(playerData);
          statData.push(playerData);
          statData.push(playerData);
          statData.push(playerData);
          statData.push(playerData);
          statData.push(playerData);
          statData.push(playerData);
          setTableData(statData);
          setBracketData(response.data.data);
          dispatch(toggleLoading(false));
        })
        .catch(err => {
          dispatch(toggleLoading(false));
          console.log(err.response ? err.response.data.message : err);
        });
    };
    loadBracket();

  }, [dispatch, userStats]);

  return (
    <Box>
      <Box textAlign="center">
        <img src={BuddyHeader} alt="GAC Buddy" />
      </Box>
      <Box py={2}>
        <Container maxWidth="lg">
          <Typography align="center" variant="h4" component="h1">
            Your GAC Bracket
          </Typography>
          <SelectOpponent className='gas-hide-lg' pt={2} />
        </Container>
      </Box>
      <Box pb={4} pt={2}>
        <Container maxWidth="lg">
          {opponent && <Box className='gas-hide-lg'><PlayCompare opponent={opponent} /></Box>}
          {bracketData.length > 0 && <CompareBracket data={tableData} />}
        </Container>
      </Box>

    </Box>
  );
}

export default BracketPage;